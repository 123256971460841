import { combineReducers } from 'redux';
import authorisation from '../authorisation/reducer';
// import registration from './registration/reducer';
// import login from './obsoleteLogin/reducer';
// import passwordRecovery from './passwordRecovery/reducer';
// import onboarding from './onboarding/reducer';
// import documents from './documents/reducers';
// import groups from './groups/reducer';
import core from './core/reducers';
// import myProfile from './myProfile/reducers';
// import hospitals from './hospitals/reducers';
// import termsOfUse from './termsOfUse/reducers';
// import dashboardUsers from './dashboardUsers/reducers';
import organisation from '@organisation/redux/reducers';
// import weblinks from './weblinks/reducers';
import entry from '@entry/redux/reducers';
import home from '@home/redux/reducers';

export type IState = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
  authorisation,
  // appUsers,
  // contentMessages,
  core,
  ...entry,
  ...home,
  // dashboardUsers,
  // documents,
  // groups,
  // hospitals,
  // login,
  // myProfile,
  // onboarding,
  organisation,
  // passwordRecovery,
  // registration,
  // termsOfUse,
  // weblinks,
});

export default rootReducer;
