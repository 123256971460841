import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Container,
  HeaderImage,
  validatePasswordComplexity,
  compareToFirstPassword,
} from '@common';
import { Button } from '@liquid-state/ui-kit';
import { Alert, Card, Form, Input, Icon } from 'antd';
import { settings } from '../../assets';
import { onChangePassword as changePasswordAction } from '../../redux/actions';

class ChangePassword extends Component {
  static propTypes = {
    error: PropTypes.oneOfType([
      PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
        message: PropTypes.string,
      }),
      PropTypes.bool,
    ]).isRequired,
    form: PropTypes.shape().isRequired,
    loading: PropTypes.bool.isRequired,
    onChangePassword: PropTypes.func.isRequired,
  };

  state = {
    confirmDirty: false,
    submitted: false,
  };

  validateWithConfirmPassword = (rule, value, callback) => {
    const {
      props: { form },
      state: { confirmDirty },
    } = this;
    if (value && confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    const { confirmDirty } = this.state;
    this.setState({ confirmDirty: confirmDirty || !!value });
  };

  handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    const { form, onChangePassword } = this.props;
    const { submitted } = this.state;
    const errors = form.getFieldsError();

    if (Object.values(errors).some(error => error !== undefined)) {
      return;
    }

    if (!submitted) {
      this.setState({ submitted: true });
    }

    const { oldpassword, newpassword } = form.getFieldsValue();

    onChangePassword(oldpassword, newpassword);
  };

  checkSubmitDisabled = () => {
    const { form } = this.props;
    const errors = form.getFieldsError();
    const values = form.getFieldsValue();

    if (!Object.keys(errors).length || !Object.keys(values).length) {
      return true;
    }

    return Object.keys(errors).some(
      key => errors[key] !== undefined || !values[key],
    );
  };

  checkPasswordDifferent = (rule, value, callback) => {
    const {
      form: { getFieldValue },
    } = this.props;

    if (value === getFieldValue('oldpassword')) {
      return callback('New password must be different to old');
    }

    return callback();
  };

  render() {
    const {
      error,
      form: { getFieldDecorator, getFieldValue },
      loading,
    } = this.props;
    const { submitted } = this.state;

    return (
      <Container className="settings">
        <HeaderImage imgUrl={settings} />
        <Card>
          <h1>Change password</h1>
          <p>
            Your password must be at least eight characters long, and must
            include at least one number, one uppercase letter, and one lowercase
            letter
          </p>
          <Form onSubmit={this.handleSubmit}>
            {submitted && !loading ? (
              <Alert
                type={error ? 'error' : 'success'}
                message={error ? error.message : 'Password succesfully changed'}
                showIcon
              />
            ) : null}
            <Form.Item label="Old password" hasFeedback>
              {getFieldDecorator('oldpassword', {
                rules: [
                  {
                    required: true,
                    message: 'You must enter your old password',
                  },
                  { validator: validatePasswordComplexity },
                ],
              })(
                <Input
                  type="password"
                  placeholder="Enter your old password"
                  prefix={<Icon type="lock" />}
                />,
              )}
            </Form.Item>
            <Form.Item label="New password" hasFeedback>
              {getFieldDecorator('newpassword', {
                rules: [
                  { required: true, message: 'You must enter a new password' },
                  { validator: validatePasswordComplexity },
                  { validator: this.validateWithConfirmPassword },
                  { validator: this.checkPasswordDifferent },
                ],
              })(
                <Input
                  type="password"
                  placeholder="Enter your new password"
                  prefix={<Icon type="lock" />}
                />,
              )}
            </Form.Item>
            <Form.Item label="Confirm new password" hasFeedback>
              {getFieldDecorator('confirm', {
                rules: [
                  {
                    required: true,
                    message: 'You need to confirm your new password',
                  },
                  {
                    validator: (rule, value, callback) =>
                      compareToFirstPassword(
                        getFieldValue('newpassword'),
                        value,
                        callback,
                      ),
                  },
                ],
              })(
                <Input
                  type="password"
                  placeholder="Re-enter your new password"
                  prefix={<Icon type="lock" />}
                />,
              )}
            </Form.Item>
            <Button
              className={loading ? 'disabled' : ''}
              type="primary"
              htmlType="submit"
              stretched
            >
              Change password
            </Button>
          </Form>
        </Card>
      </Container>
    );
  }
}

export const ChangePasswordForm = Form.create()(ChangePassword);

const mapStateToProps = state => ({
  error: state.settings.error,
  loading: state.settings.loading,
});

export default connect(
  mapStateToProps,
  { onChangePassword: changePasswordAction },
)(ChangePasswordForm);
