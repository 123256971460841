export const REFRESH_CONTENT = 'REFRESH_CONTENT';
export const REFRESH_CONTENT_SUCCEEDED = 'REFRESH_CONTENT_SUCCEEDED';
export const REFRESH_CONTENT_FAILED = 'REFRESH_CONTENT_FAILED';
export const REFRESHED_DOCUMENT_DETAILS = 'REFRESHED_DOCUMENT_DETAILS';
export const REFRESH_ALL_DOCUMENT_DETAILS_SUCCESS =
  'REFRESH_ALL_DOCUMENT_DETAILS_SUCCESS';

export const SEARCH_DOCUMENTS = 'SEARCH_DOCUMENTS';
export const SEARCH_DOCUMENTS_FAILED = 'SEARCH_DOCUMENTS_FAILED';
export const SEARCH_DOCUMENTS_SUCCEEDED = 'SEARCH_DOCUMENTS_SUCCEEDED';
export const DEACTIVATE_DOCUMENT_SEARCH = 'DEACTIVATE_DOCUMENT_SEARCH';

export const OPEN_DOCUMENT = 'OPEN_DOCUMENT';
export const OPEN_PAGE_REFERENCE = 'OPEN_PAGE_REFERENCE';

export const LOAD_FAVOURITES = 'LOAD_FAVOURITES';
export const FAVOURITES_LOADED = 'FAVOURITES_LOADED';
export const TOGGLE_FAVOURITE = 'TOGGLE_FAVOURITE';

export const FETCH_PROFILE = 'FETCH_PROFILE';
export const LOAD_PROFILE = 'LOAD_PROFILE';

export const LOGOUT = 'LOGOUT';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const OPEN_EXTERNAL_LINK = 'OPEN_EXTERNAL_LINK';

export const SET_ONLINE_STATUS = 'SET_ONLINE_STATUS';

export const UPDATE_SEARCH_TEXT = 'UPDATE_SEARCH_TEXT';

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const SEND_PERSONAL_DATA = 'SEND_PERSONAL_DATA';

// for favourites string in ubiquity `${documentId}${FAVOURITES_DELIMITER}${slug}`
export const FAVOURITES_DELIMITER = '^~~@@~~^';

export const ENABLE_NOTIFICATIONS = 'ENABLE_NOTIFICATIONS';
