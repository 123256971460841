import React from 'react';
import Types from 'prop-types';
import './style.less';

const HeaderImage = ({ imgUrl }) => (
  <div className="header-image-container">
    <div
      className="header-image"
      style={{ backgroundImage: `url(${imgUrl})` }}
    />
    <span className="gradient-footer" />
  </div>
);

HeaderImage.propTypes = {
  imgUrl: Types.string.isRequired,
};

export default HeaderImage;
