import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@liquid-state/ui-kit';
import { Form, Input, Icon, Alert, Card } from 'antd';
import { Container, PinnedToBottom, HeaderImage } from '@common';
import { passwordResetStarted } from '../../redux/actions/authentication';
import { register } from '../../assets';

const FormItem = Form.Item;

class Email extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
  };

  static defaultProps = {
    error: undefined,
  };

  getError() {
    const { error } = this.props;
    return error ? <Alert message={error} type="error" showIcon /> : null;
  }

  handleSubmit = event => {
    event.preventDefault();
    const { form, onSubmit } = this.props;
    form.validateFieldsAndScroll((error, values) => {
      if (!error) {
        onSubmit(Object.assign({}, values));
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;

    return (
      <Container fixed noPadding className="onboarding">
        <HeaderImage imgUrl={register} />
        <Card>
          <h1>Forgotten password</h1>
          <h5 className="recover-subheading">Send verification email</h5>
          <p>
            Please enter your email below to receive an authentication code.
          </p>
          <Form onSubmit={this.handleSubmit} className="forgotten">
            {this.getError()}
            <FormItem hasFeedback>
              {getFieldDecorator('email', {
                rules: [
                  {
                    type: 'email',
                    message: 'Please enter a valid email address',
                  },
                  {
                    required: true,
                    message: 'Your email is required to continue',
                  },
                ],
              })(
                <Input
                  placeholder="Enter your email address"
                  prefix={<Icon type="user" />}
                  type="email"
                />,
              )}
            </FormItem>
            <PinnedToBottom>
              <Button stretched type="primary" htmlType="submit">
                Send Email
              </Button>
            </PinnedToBottom>
          </Form>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  error: state.passwordReset.error,
});

const actions = {
  onSubmit: passwordResetStarted,
};

export const RecoveryEmail = Form.create()(Email);

export default connect(
  mapStateToProps,
  actions,
)(RecoveryEmail);
