import React, { useEffect, useContext } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { ButtonGrid, Container, ContentPadding, HeaderImage } from "@common";
import { useExternalLink } from "@common/linking";
import { AppContext } from "@common";
import { MediaButton } from "@liquid-state/ui-kit";
import { Card, Icon } from "antd";
import { home } from "../../assets";

import { refreshContent } from "../../redux/actions";

import "./styles.less";

const contactInfo = [
  {
    buttonText: "Publications",
    icon: "book",
    url: "publications"
  },
  {
    buttonText: "Favourites",
    icon: "star",
    url: "favourites"
  },
  {
    buttonText: "Contact us",
    icon: "mail",
    url: ""
  },
  {
    buttonText: "Settings",
    icon: "setting",
    url: "settings"
  }
];

export const HomePage = ({ history, refresh }) => {
  const app = useContext(AppContext);
  const externalLink = useExternalLink(app);
  const email = useSelector(state => state.login?.email);

  useEffect(() => {
    console.log("Calling refresh() from useEffect with profile:", email);
    if (email.length > 0) {
      refresh();
    }
    document.title = "Sonic Edu";
  }, [email, refresh]);

  // useEffect(() => {
  //   if (!hasEnabledNotifications) {
  //     dispatch(enableNotificationsAction());
  //     setHasEnabledNotifications(true);
  //   }
  // }, [dispatch, hasEnabledNotifications]);

  return (
    <Container className="home-page">
      {/* <ExternalLinkModal settingsURL="URL_CONTACT_US" isVisible={isModalVisible} /> */}
      <HeaderImage imgUrl={home} />
      <Card>
        <ContentPadding>
          <h1>Home</h1>
          <p>
            Welcome to <strong>Sonic Edu</strong> - a pathology reference guide
            and educational resource, developed from the medical expertise of
            over 400 pathologists who work at Sonic Pathology Australia.
          </p>
          <ButtonGrid size="large">
            {contactInfo.map(({ buttonText, icon, url }) => (
              <MediaButton
                key={`${buttonText}${url}`}
                onClick={() =>
                  buttonText === "Contact us"
                    ? externalLink("URL_CONTACT_US")
                    : history.push(url)
                }
              >
                <Icon type={icon} />
                {buttonText}
              </MediaButton>
            ))}
          </ButtonGrid>
        </ContentPadding>
      </Card>
    </Container>
  );
};

HomePage.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  refresh: PropTypes.func
};

HomePage.defaultProps = {
  refresh: () => {}
};

export default connect(null, {
  refresh: refreshContent
})(HomePage);
