import { useContext } from "react";
import { Modal } from "antd";
import { Messages } from "@liquid-state/iwa-core";
import { AppContext } from "@common";

const { confirm } = Modal;

const handleOK = async (app, settingsURL) => {
  let realURL = settingsURL;
  if (settingsURL.indexOf("http") !== 0) {
    const values = await app.configuration(settingsURL);
    realURL = values[settingsURL];
  }
  app.communicator.send(Messages.launch.browser(realURL, true));
};

export const useExternalLink = () => {
  const app = useContext(AppContext);

  return settingsURL =>
    confirm({
      title: "You are leaving Sonic Edu.",
      content: "Are you sure you wish to continue?",
      onOk() {
        handleOK(app, settingsURL);
      }
    });
};

export const openExternalLink = (app, settingsURL) => {
  confirm({
    title: "You are leaving Sonic Edu.",
    content: "Are you sure you wish to continue?",
    onOk() {
      handleOK(app, settingsURL);
    }
  });
};
