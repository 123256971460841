import IdentityPlugin from '@liquid-state/iwa-identity';
import apiGatewayFactory from 'aws-api-gateway-client';

export default app => {
  // const createClient = async (identity) => {
  //   const { UIS_URL, AWS_REGION } = await app.configuration('UIS_URL', 'AWS_REGION');
  //   return apiGatewayFactory.newClient({
  //     invokeUrl: UIS_URL,
  //     accessKey: identity.credentials.accessKeyId,
  //     secretKey: identity.credentials.secretAccessKey,
  //     sessionToken: identity.credentials.sessionToken,
  //     region: AWS_REGION,
  //   });
  // };

  const createClient = async jwt => {
    const { UIS_URL, AWS_REGION } = await app.configuration(
      'UIS_URL',
      'AWS_REGION',
    );
    return apiGatewayFactory.newClient({
      invokeUrl: UIS_URL,
      region: AWS_REGION,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
  };

  return {
    register: async () => {
      const identity = await app
        .use(IdentityPlugin)
        .forService('cognito')
        .getIdentity();
      // const client = await createClient(identity);
      const jwt = identity.identifiers.get('jwt');
      const client = await createClient(jwt);
      // eslint-disable-next-line camelcase
      const { app_token } = await app.configuration('app_token');
      const cognitoUsername = identity.name.replace('@', '*');
      return client.invokeApi(
        {},
        'user/register/',
        'POST',
        {},
        { app_token, idp_username: cognitoUsername },
      );
    },
    getTokens: async () => {
      const identity = await app
        .use(IdentityPlugin)
        .forService('cognito')
        .getIdentity();
      // const client = await createClient(identity);
      const jwt = identity.identifiers.get('jwt');
      const client = await createClient(jwt);
      return client.invokeApi({}, 'user/jwt/', 'GET');
    },
    deleteAccount: async () => {
      const identity = await app
        .use(IdentityPlugin)
        .forService('cognito')
        .getIdentity();
      // const client = await createClient(identity);
      const jwt = identity.identifiers.get('jwt');
      const client = await createClient(jwt);
      const email = await new Promise(resolve => {
        identity.credentials.user.getUserAttributes((err, attributes) => {
          attributes.forEach(attr => {
            if (attr.Name === 'email') {
              resolve(attr.Value);
            }
          });
        });
      });
      try {
        return await client.invokeApi({}, 'user/', 'DELETE', {}, { email });
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    sendPersonalInformation: async email => {
      const identity = await app
        .use(IdentityPlugin)
        .forService('cognito')
        .getIdentity();
      // const client = await createClient(identity);
      const jwt = identity.identifiers.get('jwt');
      const client = await createClient(jwt);
      try {
        return await client.invokeApi(
          {},
          'user/send_personal_data/',
          'POST',
          {},
          { email },
        );
      } catch (e) {
        console.log(e);
        return null;
      }
    },
  };
};
