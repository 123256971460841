import {
  REFRESH_CONTENT,
  REFRESH_CONTENT_FAILED,
  REFRESH_CONTENT_SUCCEEDED,
} from '../const';

const initialState = Object.freeze({
  documents: [],
  documentNames: {},
  categories: [],
  bundles: [],
  isRefreshing: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_CONTENT:
      return { ...state, isRefreshing: true };

    case REFRESH_CONTENT_FAILED:
      return { ...state, isRefreshing: false };

    case REFRESH_CONTENT_SUCCEEDED:
      return { ...state, ...action.payload.content, isRefreshing: false };

    default:
      return state;
  }
};
