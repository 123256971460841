import { takeEvery, getContext } from 'redux-saga/effects';
import { openExternalLink } from '@common/linking';
import { OPEN_EXTERNAL_LINK } from '../const';

export default function*() {
  yield takeEvery(OPEN_EXTERNAL_LINK, doExternal);
}

function* doExternal({ payload }) {
  const app = yield getContext('app');
  openExternalLink(app, payload.url);
}
