import { combineReducers } from 'redux';
import {
  appId,
  appToken,
  ubiquityCompanyId,
  idmOrganisation,
  organisationSlug,
  videoUbiquityExportDetails,
} from 'settings';
import { ISetAppDetails } from './actions';
import { IAppDetails } from './types';
import { IState } from '@redux/reducer';

const appDetailsInitialState: IAppDetails = {
  appId,
  appToken,
  companyId: ubiquityCompanyId,
  organisationId: idmOrganisation,
  organisationSlug,
  solutionConfiguration: videoUbiquityExportDetails,
};
function appDetails(state: IAppDetails = appDetailsInitialState, action: ISetAppDetails) {
  switch (action.type) {
    case 'organisation/set-app-details':
      return action.payload.appDetails;
    default:
      return state;
  }
}

export const selectOtherInfoLabels = () => (state: IState) => {
  const conf = state.organisation.appDetails.solutionConfiguration;
  const sonicedu = conf.pep_sonicedu || {};
  const result = sonicedu.app_user_other_info_labels || { en: {} };
  return result;
};

export default combineReducers({ appDetails });
