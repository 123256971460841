import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, Input, message, Card } from "antd";
import { Container, HeaderImage } from "@common";
import { Button } from "@liquid-state/ui-kit";
import { settings } from "../../assets";
import { sendPersonalData } from "../../redux/actions";

const SendData = Form.create()(({ form, onSubmit, history }) => {
  const doSubmit = () => {
    form.validateFieldsAndScroll((err, { email }) => {
      if (err) {
        return;
      }
      message.success(
        "Thank you for your request, we will be in contact with you in three business days."
      );
      history.goBack();
      onSubmit(email);
    });
  };

  return (
    <Container className="settings">
      <HeaderImage imgUrl={settings} />
      <Card>
        <h1>Send me a copy of my personal data</h1>
        <p>
          Please enter your email address to log a personal data request. You
          will be contacted within 3 business days, to notify you that the
          request has been received and is being processed. Once the request has
          been fulfilled, you will be sent a copy of your personal data that has
          been collected by Sonic Edu.
        </p>
        <Form onSubmit={doSubmit}>
          <Form.Item label="E-mail">
            {form.getFieldDecorator("email", {
              rules: [
                {
                  required: true,
                  message:
                    "An email address is required to send your personal data"
                }
              ]
            })(<Input type="email" />)}
          </Form.Item>
        </Form>
        <Button type="primary" stretched onClick={doSubmit}>
          Email my personal data
        </Button>
      </Card>
    </Container>
  );
});

SendData.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default connect(null, { onSubmit: sendPersonalData })(SendData);
