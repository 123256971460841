import React from "react";
// import { hot } from 'react-hot-loader';
import { Switch } from "react-router-dom";
import { Route } from "@common";

import Entry from "./Entry";
import Welcome from "./Welcome";
import ValueStatement from "./ValueStatement";
import Personalise from "./Personalise";
import Register from "./Register";
import { TermsAndConditions, PrivacyPolicy } from "./TermsAndConditions";
import Login from "./Login";
import Verification from "./Verification";
import ApplyForSourceCode from "./ApplyForSourceCode";
import PasswordRecovery from "./PasswordRecovery";
import "./style.less";

const Index = ({ match }) => {
  return (
    // <ErrorTrap>
    <Switch>
      <Route path={match.path} component={Entry} exact />
      <Route path={`${match.path}/login`} component={Login} />
      <Route path={`${match.path}/registration`} component={Register} exact />
      <Route
        path={`${match.path}/registration/verification`}
        component={Verification}
        exact
      />
      <Route
        path={`${match.path}/registration/welcome`}
        component={Welcome}
        exact
      />
      <Route
        path={`${match.path}/registration/value`}
        component={ValueStatement}
        exact
      />
      <Route
        path={`${match.path}/registration/personalise`}
        component={Personalise}
        exact
      />
      <Route
        path={`${match.path}/registration/tou`}
        component={TermsAndConditions}
        exact
      />
      <Route
        path={`${match.path}/registration/privacy`}
        component={PrivacyPolicy}
        exact
      />
      <Route path={`${match.path}/recovery`} component={PasswordRecovery} />
      <Route
        path={`${match.path}/apply`}
        component={ApplyForSourceCode}
        exact
      />
    </Switch>
    // </ErrorTrap>
  );
};

export default Index;
// export default hot(module)(Index);
