import {
  REFRESH_CONTENT,
  REFRESH_ALL_DOCUMENT_DETAILS_SUCCESS,
} from '../const';

export default (state = false, action) => {
  switch (action.type) {
    case REFRESH_CONTENT:
      return true;
    case REFRESH_ALL_DOCUMENT_DETAILS_SUCCESS:
      return false;
    default:
      return state;
  }
};
