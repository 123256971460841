import { AccessDenied } from "./index";

export const AnyOf = (...permissions) => ({
  evaluate: userPermissions =>
    permissions.some(p => userPermissions.includes(p))
});

// export const useEvaluatePermissions = (...permissions) => {
export const useEvaluatePermissions = () => {
  // const userPermissions = useSelector(selectPermissionsForUser);
  // const ok = permissions.every(p => {
  //   if (p.evaluate) {
  //     return p.evaluate(userPermissions);
  //   }
  //   return userPermissions.includes(p);
  // });
  const ok = () => true;
  return ok;
};

export const useRequiresPermissions = (...permissions) => {
  const ok = useEvaluatePermissions(permissions);
  if (!ok) {
    throw new AccessDenied();
  }
};

export const useHasPermissions = (...permissions) => {
  // const userId = useSelector(state => state.login.user?.id);
  // const userPermissions = useSelector(selectPermissionsForUser);

  // return useMemo(() => {
  //   if (permissions.length === 0) {
  //     return userPermissions.reduce((acc, next) => ({ ...acc, [next]: true }), {});
  //   }
  //   return permissions.reduce(
  //     (acc, next) => ({
  //       ...acc,
  //       [next]: userPermissions.includes(next),
  //     }),
  //     {},
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userId]);

  return permissions.reduce(
    (acc, next) => ({
      ...acc,
      [next]: true
    }),
    {}
  );
};
