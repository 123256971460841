/* eslint-disable */
import React from "react";
import { useExternalLink } from "./linking";

import "./style.less";

export default () => {
  const externalLink = useExternalLink();
  const handleLink = url => {
    return e => {
      e.preventDefault();
      externalLink(url);
    };
  };

  return (
    <div className="legal-document">
      <h1>Terms and Conditions</h1>

      <h2>Sonic Edu &mdash; iOS, Android, and Web apps</h2>

      <h3>General</h3>
      <p>
        In this document:
        <ul>
          <li>
            Sonic Healthcare Limited (ABN 24 004 196 909) is variously referred
            to as <strong>Sonic Healthcare</strong>, <strong>Sonic</strong>,{" "}
            <strong>Us</strong> and <strong>We</strong>.
            <strong>Sonic Edu</strong> is used to refer to a cross-platform
            Application, available as native mobile Apps on <strong>iOS</strong>{" "}
            and <strong>Android</strong> operating systems, and as a{" "}
            <strong>Web App</strong> accessible in most modern web browsers.
            Sonic Edu may also be referred to as a <strong>Service</strong>{" "}
            throughout these policies.
          </li>
          <li>
            For the purpose of these policies, the terms <strong>App</strong>{" "}
            and <strong>Application</strong> are defined as: a digital tool that
            allows for two-way transmission of data between its Sonic Healthcare
            and the App's Users. For example, Sonic Edu.
            <ul>
              <li>
                App/Application may be used interchangeably to refer to mobile
                Apps on iOS and Android, or the Web App.
              </li>
            </ul>
          </li>
          <li>
            <strong>User(s)</strong> are defined as any individual or
            individuals, acting on their own behalf or the behalf of a larger
            organisation, who access Sonic Edu on any platform, in any capacity.
          </li>
          <li>
            <strong>You</strong> are defined as: a User of Sonic Edu; an
            authorised representative of your larger organisation, and; the
            reader of the policies herein.
          </li>
        </ul>
      </p>

      <p>
        Sonic Healthcare provides Sonic Edu as a Free App. This Service is
        provided by Sonic Healthcare at no cost and is intended for use as-is.
      </p>
      <p>
        This document should be considered as an addendum, and should be read
        alongside Sonic Healthcare Limited&apos;s{" "}
        <a
          href="#"
          onClick={handleLink(
            "https://www.sonichealthcare.com/privacy-policy/"
          )}
        >
          Privacy Policy
        </a>
        .
      </p>
      <p>
        Sonic Healthcare may change its Privacy Policy and/or Terms and
        Conditions from time to time, and at Sonic Healthcare&apos;s sole
        discretion. Sonic Healthcare encourages visitors to frequently check
        this page for any changes to its Privacy Policy and/or Terms and
        Conditions. Your continued use of Sonic Edu after any change in the
        Privacy Policy or Terms and Conditions will constitute your acceptance
        of such changes.
      </p>

      <h3>Terms &amp; Conditions</h3>

      <p>
        By downloading or using Sonic Edu, these terms will automatically Apply
        to you. Therefore, you should make sure that you read them carefully
        before using Sonic Edu.
      </p>
      <p>
        Sonic Edu has been built and published by Sonic Healthcare. It is being
        made available to members of the Australian medical community, medical
        students, paramedical professionals and others who may be assisted in
        the interpretation of results, the care of their patients, or in the
        course of their studies.
      </p>
      <p>
        Every care has been taken to ensure that the information in Sonic Edu is
        accurate, and up-to-date. Nonetheless, minor nationwide variations in
        some testing methods, and differences in medical opinion mean that you
        may wish to obtain further advice from time to time, from your local
        Sonic Healthcare laboratory or other sources. The publications within
        Sonic Edu are intended for use by medical practitioners as a general
        guide only. No reader should act, or fail to act, on the basis of this
        publication.
      </p>
      <p>
        Sonic Healthcare Limited, its officers, the authors and editor, disclaim
        all and any liability for any error or omission contained in a
        publication within Sonic Edu, or arising from any action taken or not
        taken because of information provided in this publication.
      </p>
      <p>
        You&apos;re not allowed to copy or modify Sonic Edu, any part of Sonic
        Edu, or Sonic Healthcare trademarks, in any way. You&apos;re not allowed
        to attempt to extract the source code of Sonic Edu, or try to translate
        Sonic Edu into other languages, or make derivative versions. Sonic Edu
        itself, and all the trade marks, copyright, database rights and other
        intellectual property rights related to it, belong to Sonic Healthcare.
      </p>
      <p>
        Sonic Healthcare is committed to ensuring that Sonic Edu is as useful
        and efficient as possible. For that reason, we reserve the right to make
        changes to Sonic Edu or to charge for its services, at any time and for
        any reason. We will never charge you for Sonic Edu or its services
        without making it very clear to you exactly what you’re paying for.
      </p>
      <p>
        For Users of the native mobile App(s), Sonic Edu is only supported on
        the two most recent major operating system versions of Android and iOS.
        For Users of the web App, Sonic Edu is supported for the two most recent
        software versions of a major web browser of your choice, within the two
        most recent major operating system versions of your computer. The
        requirements for both systems (and for any additional systems we decide
        to extend the availability of the App to) may change. In this case, you
        will need to update your operating system/environment if you want to
        keep using Sonic Edu. If you jailbreak or root your device, Sonic Edu is
        not guaranteed to work properly, or at all.
      </p>
      <p>
        Certain functions of Sonic Edu require an active internet connection.
        Whilst some features in the iOS and Android Apps may work without an
        internet connection, the Web App will always require an internet
        connection.
      </p>

      <h3>Contact Us</h3>
      <p>
        If you have any questions or suggestions about our Privacy Policy or
        Terms and Conditions, do not hesitate to{" "}
        <a
          href="#"
          onClick={handleLink(
            "https://www.sonichealthcare.com/contact/key-contacts/"
          )}
        >
          contact us
        </a>
        .
      </p>
      <p>
        We would Appreciate any feedback on Sonic Edu as we seek to maximise its
        scope, accuracy and relevance.
      </p>
      <br />
      <p>&copy; Sonic Healthcare 2015-2023</p>
    </div>
  );
};
