import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';

const skeletons = new Array(4).fill(Skeleton, 0, 10);

/* eslint-disable react/no-array-index-key */
const SkeletonList = ({ active, children }) =>
  active ? (
    <Fragment>
      {skeletons.map((Element, index) => (
        <Element
          active
          key={`skeleton-${index}`}
          loading
          paragraph={{ rows: 4 }}
          title
        >
          <h2>Loading...</h2>
          <p>Loading...</p>
        </Element>
      ))}
    </Fragment>
  ) : (
    children
  );
/* eslint-enable react/no-array-index-key */

SkeletonList.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
};

SkeletonList.defaultProps = {
  active: false,
  children: null,
};

export default SkeletonList;
