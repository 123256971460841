import { Language, ISetLanguages, ISetOwners, Owner } from './actions';
import { combineReducers } from 'redux';
import { IState } from '@redux/reducer';

const languagesInitial: { list: string[]; byId: { [key: string]: Language } } = {
  list: [],
  byId: {},
};

const languages = (state = languagesInitial, action: ISetLanguages) => {
  switch (action.type) {
    case 'core/setLanguages':
      return {
        list: action.payload.languages.map(l => l.key),
        byId: action.payload.languages.reduce(
          (acc, l) => ({
            ...acc,
            [l.key]: l,
          }),
          {},
        ),
      };
    default:
      return state;
  }
};

const ownersInitial: { list: string[]; byId: { [key: string]: Owner } } = {
  list: [],
  byId: {},
};

const owners = (state = ownersInitial, action: ISetOwners) => {
  switch (action.type) {
    case 'core/setOwners':
      return {
        list: action.payload.owners.map(o => o.key),
        byId: action.payload.owners.reduce(
          (acc, o) => ({
            ...acc,
            [o.key]: o,
          }),
          {},
        ),
      };
    default:
      return state;
  }
};

export default combineReducers({
  languages,
  owners,
});

export const selectLanguages = (state: IState) =>
  state.core.languages.list.map((l: string) => state.core.languages.byId[l]);
export const selectLanguage = (key: string) => (state: IState): Language | undefined =>
  state.core.languages.byId[key?.toLowerCase()];
export const selectOwners = (state: IState) =>
  state.core.owners.list.map((o: string) => state.core.owners.byId[o]);
export const selectOwner = (key: string) => (state: IState): Owner | undefined => {
  return state.core.owners.byId[key?.toLowerCase()];
};
