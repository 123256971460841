import React from 'react';
import PropTypes from 'prop-types';

const LibraryHeader = ({ src, children }) => (
  <div className="library-header" style={{ backgroundImage: `url(${src})` }}>
    {children}
  </div>
);

LibraryHeader.propTypes = {
  src: PropTypes.string.isRequired,
  children: PropTypes.node,
};

LibraryHeader.defaultProps = {
  children: null,
};

export default LibraryHeader;
