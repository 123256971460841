/* eslint-disable */
import React from "react";
import { useExternalLink } from "./linking";

import { Icon, Collapse } from "antd";
const { Panel } = Collapse;

const customPanelStyle = {
  background: "#f7f7f7",
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: "hidden"
};

export default () => {
  const externalLink = useExternalLink();
  const handleLink = url => {
    return e => {
      e.preventDefault();
      externalLink(url);
    };
  };

  return (
    <div className="legal-document">
      <h1>Privacy Policy</h1>

      <h2>Sonic Edu &mdash; iOS, Android, and Web apps</h2>

      <h3>General</h3>
      <p>
        In this document:
        <ul>
          <li>
            Sonic Healthcare Limited (ABN 24 004 196 909) is variously referred
            to as <strong>Sonic Healthcare</strong>, <strong>Sonic</strong>,{" "}
            <strong>Us</strong> and <strong>We</strong>.
            <strong>Sonic Edu</strong> is used to refer to a cross-platform
            Application, available as native mobile Apps on <strong>iOS</strong>{" "}
            and <strong>Android</strong> operating systems, and as a{" "}
            <strong>Web App</strong> accessible in most modern web browsers.
            Sonic Edu may also be referred to as a <strong>Service</strong>{" "}
            throughout these policies.
          </li>
          <li>
            For the purpose of these policies, the terms <strong>App</strong>{" "}
            and <strong>Application</strong> are defined as: a digital tool that
            allows for two-way transmission of data between its Sonic Healthcare
            and the App's Users. For example, Sonic Edu.
            <ul>
              <li>
                App/Application may be used interchangeably to refer to mobile
                Apps on iOS and Android, or the Web App.
              </li>
            </ul>
          </li>
          <li>
            <strong>User(s)</strong> are defined as any individual or
            individuals, acting on their own behalf or the behalf of a larger
            organisation, who access Sonic Edu on any platform, in any capacity.
          </li>
          <li>
            <strong>You</strong> are defined as: a User of Sonic Edu; an
            authorised representative of your larger organisation, and; the
            reader of the policies herein.
          </li>
        </ul>
      </p>

      <p>
        Sonic Healthcare provides Sonic Edu as a Free App. This Service is
        provided by Sonic Healthcare at no cost and is intended for use as-is.
      </p>
      <p>
        This document should be considered as an addendum, and should be read
        alongside Sonic Healthcare Limited&apos;s{" "}
        <a
          href="#"
          onClick={handleLink(
            "https://www.sonichealthcare.com/privacy-policy/"
          )}
        >
          Privacy Policy
        </a>
        .
      </p>
      <p>
        Sonic Healthcare may change its Privacy Policy and/or Terms and
        Conditions from time to time, and at Sonic Healthcare&apos;s sole
        discretion. Sonic Healthcare encourages visitors to frequently check
        this page for any changes to its Privacy Policy and/or Terms and
        Conditions. Your continued use of Sonic Edu after any change in the
        Privacy Policy or Terms and Conditions will constitute your acceptance
        of such changes.
      </p>

      <h3>Privacy Policy</h3>

      <p>
        Sonic Healthcare is committed to ensuring the privacy and
        confidentiality of your personal information.
      </p>
      <p>
        This document is used to inform visitors about our policies regarding
        the collection, use, and disclosure of Personal Information of Sonic Edu
        Users.
      </p>
      <p>
        By choosing to use Sonic Edu, you agree to the collection and use of
        information in relation to this policy. The Personal Information that we
        collect is used for providing and improving Sonic Edu. We will not use
        or share your information with anyone except as described in this
        Privacy Policy.
      </p>

      <h4>Log Data</h4>
      <p>
        When you use Sonic Edu we collect data and information (through third
        party products), called "Log Data". This Log Data may include
        information such as your device Internet Protocol (“IP”) address, device
        name, operating system version, the configuration of the App when
        utilising our Service, the time and date of your use of the Service, and
        other statistics.
      </p>

      <h4>Information Collection and Use</h4>
      <p>
        When you use Sonic Edu, we may require you to provide us with certain
        personally identifiable information. We collect this information in
        order to develop a better understanding about Users of the App, in order
        to continually deliver improvements to the Service. Some collected
        information is crucial to in-App functionality. Sonic Healthcare does
        not disclose personally-identifying information other than as described
        in Sonic Healthcare Limited&apos;s{" "}
        <a
          href="#"
          onClick={handleLink(
            "https://www.sonichealthcare.com/privacy-policy/"
          )}
        >
          Privacy Policy
        </a>
        . Specific examples of the kinds of information that we collect, and the
        related technology providers, are as follows:
      </p>

      <Collapse
        bordered={false}
        // defaultActiveKey={['1']}
        expandIcon={({ isActive }) => (
          <Icon type="caret-right" rotate={isActive ? 90 : 0} />
        )}
      >
        <Panel header="Registration form" key="1" style={customPanelStyle}>
          <p>
            As a User of Sonic Edu, we will collect some information about you,
            during your registration and during subsequent sessions of activity
            within the App. This information may include, but is not limited to:
          </p>
          <ul>
            <li>
              Registration data - First name, Last name, Email address
              (Username), Date of registration, Last Login
            </li>
            <li>
              Profile data - Country, State, Usual pathology practice, User
              type, Doctor code and/or Provider number
            </li>
          </ul>
          <p>
            We need this information so that we can set up your App access, and
            continue to provide Sonic Edu to you on an ongoing basis. The
            information you provide may also help us personalise your Sonic Edu
            experience in future, by sending you documents and notifications
            related to your role, location, or practice.
          </p>
        </Panel>
        <Panel header="Analytics" key="2" style={customPanelStyle}>
          <p>
            We use a service called Mixpanel to help us collect analytics about
            how Sonic Edu is used. This helps us identify and fix issues in
            Sonic Edu, optimise how we design new versions of Sonic Edu, and
            decide on new content and features that should be added to the App.
            We collect the following data for this purpose:
          </p>
          <ul>
            <li>Location data: City, Region, Country, Time zone</li>
            <li>
              Device data: Device, Browser version, Operating system, Mixpanel
              library, Screen height, Screen width, Last seen
            </li>
            <li>
              Analytics data: Current URL, Initial referrer, Initial referring
              domain, Referrer, Referring Domain, Search engine, Search keyword,
              UTM parameters
            </li>
            <li>
              Registration data: Any details you enter while registering for the
              App, or subsequently updating your App User profile details, may
              be sent to Mixpanel
            </li>
          </ul>
          <p>
            For more information, see the{" "}
            <a
              href="#"
              onClick={handleLink(
                "https://help.mixpanel.com/hc/en-us/articles/115004613766-Default-Properties-Collected-by-Mixpanel"
              )}
            >
              Mixpanel website
            </a>
          </p>
        </Panel>
        <Panel header="Push notifications" key="3" style={customPanelStyle}>
          <p>
            For Users of our iOS or Android Sonic Edu App, we use a service
            called OneSignal to send notifications to your App. We collect the
            following data to optimise the sending of push notifications,
            including allowing us to send personalised notifications to your
            device:
          </p>
          <ul>
            <li>Location data: Time Zone, Country, Location</li>
            <li>
              Device data: Device OS, Device Rooted, Device Language, Push
              Status, App Version, In App Purchases, Google Ad Id and IFV, Your
              Application Identifier, Cellular Carrier, Device Model, IP
              Address, web_auth and web_p256, Push Tokens
            </li>
            <li>
              Analytics data: First Session Time, Last Session Time, Session
              Count, Total Usage Duration
            </li>
          </ul>
          <p>
            For more information, see the{" "}
            <a
              href="#"
              onClick={handleLink("https://onesignal.com/privacy_policy")}
            >
              OneSignal website
            </a>
          </p>
        </Panel>
        <Panel header="Platform providers" key="4" style={customPanelStyle}>
          <p>
            For Users of our iOS or Android Sonic Edu App, some information may
            be collected by/for operating system/technology platform providers
            (Apple and Google). For more information, see:
          </p>
          <ul>
            <li>
              <a
                href="#"
                onClick={handleLink(
                  "https://www.apple.com/legal/privacy/en-ww/"
                )}
              >
                Apple&apos;s privacy policy
              </a>
            </li>
            <li>
              <a
                href="#"
                onClick={handleLink("https://policies.google.com/privacy")}
              >
                Google&apos;s privacy policy
              </a>
            </li>
          </ul>
        </Panel>
      </Collapse>

      <h4>Service Providers and Personally Identifiable Information</h4>
      <p>
        We may employ third-party companies and individuals for the following
        reasons:
      </p>
      <ul>
        <li>To provide Services on our behalf;</li>
        <li>To perform Service-related work; or</li>
        <li>To assist us in analysing how our services are used.</li>
      </ul>
      <p>
        Where necessary, we may provide these third parties with your Personal
        Information, in order to support or administer Sonic Edu, or to perform
        other relevant tasks assigned to them on our behalf. However, as trusted
        contractors, they are obligated to comply with the{" "}
        <a href="https://www.oaic.gov.au/privacy/australian-privacy-principles/">
          Australian Privacy Principles
        </a>{" "}
        and Sonic Healthcare Limited's{" "}
        <a href="https://www.sonichealthcare.com/privacy-policy/">
          Privacy Policy
        </a>
      </p>

      <h4>Cookies</h4>
      <p>
        Cookies are files with a small amount of data that are commonly used as
        anonymous unique identifiers. These are sent to your web browser from
        the websites that you visit and are stored on your device's internal
        memory.
      </p>
      <p>
        Sonic Edu does not use cookies explicitly in the iOS and Android Apps.
        However, the iOS and Android Apps may use third party code and libraries
        that use cookies to collect information and improve their services.
      </p>
      <p>
        For Users of the Sonic Edu Web App, Sonic Healthcare uses cookies to
        help identify and track visitors, their usage of the Service, and their
        Web App access preferences. Visitors who do not wish to have cookies
        placed on their computers should set their browsers to refuse cookies
        before using Sonic Healthcare’s Web App or website. However, refusing
        cookies may mean that key functionality of the Service will either not
        function at all, or may not function properly without the aid of
        cookies.
      </p>

      <h4>Security</h4>
      <p>We take the protection of your personal information seriously.</p>
      <p>
        We take reasonable steps to ensure the personal information that we
        collect, use, and disclose is accurate and secure, in order to protect
        it from misuse and loss and from unauthorised access, modification, or
        disclosure.
      </p>

      <h4>Links to Other Sites</h4>
      <p>
        Sonic Edu may contain links to other sites. If you click on a
        third-party link, you will be directed to that site. Note that these
        external sites are not operated by us. Therefore, we strongly advise you
        to review the Privacy Policy of these websites. We have no control over
        and assume no responsibility for the content, privacy policies, or
        practices of any third-party sites or services.
      </p>

      <h4>Children&apos;s Privacy</h4>
      <p>
        These Services do not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from children
        under 13. In the case we discover that a child under 13 has provided us
        with personal information, we immediately delete this from our servers.
        If you are a parent or guardian and you are aware that your child has
        provided us with personal information, please contact us so that we will
        be able to undertake the necessary actions.
      </p>

      <h4>Protection of Certain Personally-Identifying Information</h4>
      <p>
        Sonic Healthcare discloses potentially personally-identifying and
        personally-identifying information only to those of its employees,
        contractors and affiliated organizations that (i) need to know that
        information in order to process it on Sonic Healthcare's behalf or to
        provide services available at Sonic Healthcare's website and Sonic
        Healthcare app, and (ii) that have agreed not to disclose it to others.
      </p>
      <p>
        As Sonic Healthcare is a global organisation, some of those employees,
        contractors and affiliated organizations may be located outside of your
        home country; by using Sonic Healthcare's website and the Sonic
        Healthcare app, you consent to the transfer of such information to them.
        Sonic Healthcare will not rent or sell potentially
        personally-identifying and personally-identifying information to anyone.
      </p>
      <p>
        Other than to its employees, contractors and affiliated organizations,
        as described above, Sonic Healthcare discloses potentially
        personally-identifying and personally-identifying information only in
        response to a subpoena, court order or other governmental request, or
        when Sonic Healthcare believes in good faith that disclosure is
        reasonably necessary to protect the property or rights of Sonic
        Healthcare, third parties or the public at large.
      </p>
      <p>
        If you are a registered user of a Sonic Healthcare app, Sonic Healthcare
        may occasionally send you an email to tell you about new features,
        solicit your feedback, or just keep you up to date with what's going on
        with Sonic Healthcare and our products. We primarily use our website to
        communicate this type of information, so this type of email would be
        kept to an absolute minimum.
      </p>
      <p>
        If you send us a request (for example via a support email or via one of
        our feedback mechanisms), we reserve the right to publish it in order to
        help us clarify or respond to your request or to help us support other
        users. Sonic Healthcare takes all measures reasonably necessary to
        protect against the unauthorized access, use, alteration or destruction
        of potentially personally-identifying and personally-identifying
        information.
      </p>

      <h4>Aggregated Statistics</h4>
      <p>
        Sonic Healthcare may collect statistics about the behaviour of visitors
        to Sonic Edu, any related websites, and any other services Sonic
        Healthcare provides. For instance, Sonic Healthcare may monitor document
        downloads within Sonic Edu. Sonic Healthcare may display this
        information publicly or provide it to others. However, Sonic Healthcare
        does not disclose personally-identifying information other than as
        described above.
      </p>
      <p></p>
      <p></p>

      <h3>Contact Us</h3>
      <p>
        If you have any questions or suggestions about our Privacy Policy or
        Terms and Conditions, do not hesitate to{" "}
        <a
          href="#"
          onClick={handleLink(
            "https://www.sonichealthcare.com/contact/key-contacts/"
          )}
        >
          contact us
        </a>
        .
      </p>
      <p>
        We would Appreciate any feedback on Sonic Edu as we seek to maximise its
        scope, accuracy and relevance.
      </p>
      <br />
      <p>&copy; Sonic Healthcare 2015-2023</p>
    </div>
  );
};
