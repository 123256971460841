import React from "react";
import {
  Container,
  PinnedToBottom,
  NavButton,
  Link,
  HeaderImage
} from "@common";
import { Card } from "antd";
import { welcome } from "../../assets";
import "./styles.less";

export default () => (
  <Container fixed noPadding className="onboarding welcome">
    <HeaderImage imgUrl={welcome} />
    <Card>
      <h1>Welcome to Sonic Edu</h1>
      <p>
        Sonic Edu provides an extensive online pathology reference library of
        general and subspecialist resources. It has been developed for
        clinicians and healthcare professionals by pathologists across the
        network of Sonic Healthcare pathology practices, making it a unique
        resource within our medical community.
      </p>
      <PinnedToBottom>
        <div className="onboarding-footer">
          <Link to="/auth/registration/value" replace>
            <NavButton type="primary">Next</NavButton>
          </Link>
        </div>
      </PinnedToBottom>
    </Card>
  </Container>
);
