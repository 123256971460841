import { REFRESHED_DOCUMENT_DETAILS } from "../const";

// This is not an inconsequential amount of work do to in mapStateToProps
// especially for long documents. So we do it here instead.
const preformatForDisplay = (pages, toc) => {
  const typeTags = ["concept", "topic", "profile", "test"];
  return {
    pages: (Array.isArray(toc) ? toc : toc.pages)
      .map(page => pages.find(p => p.PageToken === page.token))
      .map((p, index) => ({
        index,
        name: p.Name,
        type: (p.Metadata && p.Metadata.tags ? p.Metadata.tags : []).reduce(
          (prev, curr) => {
            if (curr && curr.term && typeTags.includes(curr.term))
              return curr.term;
            return prev;
          },
          "concept"
        ),
        token: p.PageToken,
        slug: p.Slug
      })),
    sections: (toc.sections || [])
      .map((s, index, array) =>
        array[index + 1]
          ? // Pairs
            [s, array[index + 1]]
          : [s, { index: undefined }]
      )
      .map(([current, next]) => ({
        name: current.name,
        pages: toc.pages.slice(current.index, next.index).map(p => p.token)
      }))
  };
};

const documentDetail = (state = {}, action) => ({
  ...state,
  ...action.payload.document,
  pages: action.payload.issue.pages,
  toc: action.payload.toc,
  forDisplay: preformatForDisplay(
    action.payload.issue.pages,
    action.payload.toc
  )
});

const initialState = {};
export default (state = initialState, action) => {
  switch (action.type) {
    case REFRESHED_DOCUMENT_DETAILS: {
      const {
        payload: { document }
      } = action;
      return {
        ...state,
        [document.product_id]: documentDetail(
          state[document.product_id] || {},
          action
        )
      };
    }

    default:
      return state;
  }
};
