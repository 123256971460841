import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Icon, Alert } from 'antd';
import { Button } from '@liquid-state/ui-kit';
import { PinnedToBottom, Link } from '@common';

const FormItem = Form.Item;

class LoginForm extends Component {
  static propTypes = {
    email: PropTypes.string.isRequired,
    form: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
    loading: PropTypes.bool,
    onOpenWebsite: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: undefined,
    loading: false,
  };

  getError() {
    const { error } = this.props;
    const message = (
      <>
        {error} If you require support, please {/* eslint-disable-next-line */}
      <a href="#" onClick={this.handleContactUs}>contact us</a>.</>
    );
    return error ? <Alert message={message} type="error" showIcon /> : null;
  }

  handleContactUs = e => {
    e.preventDefault();
    const { onOpenWebsite } = this.props;
    onOpenWebsite('URL_CONTACT_US');
  };

  handleSubmit = event => {
    event.preventDefault();
    const { form, onSubmit } = this.props;
    form.validateFieldsAndScroll((error, values) => {
      if (!error) {
        onSubmit(Object.assign({}, values));
      }
    });
  };

  render() {
    const {
      email,
      form: { getFieldDecorator },
      loading,
    } = this.props;
    return (
      <Form
        onSubmit={this.handleSubmit}
        className={`${loading ? 'loading' : ''} login`}
      >
        {this.getError()}

        <FormItem label="Email" hasFeedback>
          {getFieldDecorator('username', {
            initialValue: email,
            rules: [
              { required: true, message: 'An email address is required.' },
              { type: 'email', message: 'Please enter a valid email address' },
            ],
          })(
            <Input
              autoComplete="username"
              placeholder="Enter your email"
              prefix={<Icon type="user" />}
              type="email"
            />,
          )}
        </FormItem>

        <FormItem label="Password" hasFeedback>
          {getFieldDecorator('password', {
            initialValue: '',
            rules: [{ required: true, message: 'A password is required!' }],
          })(
            <Input
              autoComplete="current-password"
              placeholder="Enter your password"
              prefix={<Icon type="lock" />}
              type="password"
            />,
          )}
        </FormItem>
        <p>
          <Link to="/auth/recovery">Forgotten password</Link>
        </p>
        <PinnedToBottom>
          <Button stretched type="primary" htmlType="submit" loading={loading}>
            Sign In
          </Button>
        </PinnedToBottom>
        <p>
          Or <Link to="/auth/registration">register now</Link>
        </p>
      </Form>
    );
  }
}

export default Form.create()(LoginForm);
