import React from "react";
// import { hot } from 'react-hot-loader';
import { Switch } from "react-router";
import { Route } from "@common";

import Home from "./Home";
import Information from "./Information";
import DocumentDetail from "./Document";
import SettingsPage from "./Settings";
import ChangePassword from "./Settings/ChangePassword";
import TermsAndConditions from "./Settings/TermsAndConditions";
import PrivacyPolicy from "./Settings/PrivacyPolicy";
import Favourite from "./Favourites";
import DeleteAccount from "./Settings/DeleteAccount";
import SendData from "./Settings/SendData";

const Index = () => (
  // <ErrorTrap>
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/publications" exact component={Information} />
    <Route
      path="/publications/document/:productId/:pageToken"
      render={props => <DocumentDetail {...props} />}
      exact
    />
    <Route
      path="/publications/document/:productId"
      render={props => <DocumentDetail {...props} />}
    />
    {/* <Route path="/publications/document/:productId/:pageToken" component={DocumentDetail} exact />
      <Route path="/publications/document/:productId" component={DocumentDetail} /> */}
    <Route path="/settings" exact component={SettingsPage} />
    <Route path="/settings/change-password" exact component={ChangePassword} />
    <Route
      path="/settings/terms-and-conditions"
      exact
      component={TermsAndConditions}
    />
    <Route path="/settings/privacy-policy" exact component={PrivacyPolicy} />
    <Route path="/settings/delete-account" exact component={DeleteAccount} />
    <Route path="/settings/send-data" exact component={SendData} />
    <Route path="/favourites" exact component={Favourite} />
  </Switch>
  // </ErrorTrap>
);

export default Index;
// export default hot(module)(Index);
