import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form } from "antd";
import { Radio } from "@liquid-state/ui-kit";
import { PinnedToBottom, NavButton } from "@common";

const countries = [
  "Australia",
  "Belgium",
  "Germany",
  "Ireland",
  "New Zealand",
  "Switzerland",
  "United Kingdom",
  "USA",
  "Rest of the world"
];
const australianStates = [
  "Victoria",
  "New South Wales",
  "South Australia",
  "Queensland",
  "Tasmania",
  "Western Australia",
  "ACT",
  "Northern Territory"
];
const unitedStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
  "Other",
  "Don't know"
];
const practicesByState = {
  Victoria: ["Melbourne Pathology"],
  "New South Wales": [
    "Douglass Hanly Moir Pathology",
    "Southern IML Pathology",
    "Sullivan Nicolaides Pathology",
    "Capital Pathology",
    "Barratt & Smith Pathology"
  ],
  "South Australia": ["Clinpath Laboratories"],
  Queensland: ["Sullivan Nicolaides Pathology"],
  Tasmania: [
    "Hobart Pathology",
    "Launceston Pathology",
    "North West Pathology"
  ],
  "Western Australia": ["Clinipath Pathology"],
  ACT: ["Capital Pathology"],
  "Northern Territory": ["Sullivan Nicolaides Pathology"]
};
const userTypes = [
  "GP",
  "Specialist",
  "Nurse",
  "Paramedic",
  "Other healthcare professional",
  "Other medical practitioner",
  "Medical student",
  "Other health student",
  "Patient",
  "Carer",
  "Sonic Staff"
];

const fixPracticeName = practice => {
  if (practice === "Southern IML Pathology") {
    return "Southern.IML Pathology";
  }
  return practice;
};

const fixUserTypeName = practice => {
  if (practice === "Sonic Staff") {
    return "Sonic staff member";
  }
  return practice;
};

/* eslint-disable react/prefer-stateless-function */
// Needs to be class due to bug with antd https://github.com/ant-design/ant-design/issues/11324
class PersonaliseForm extends Component {
  static propTypes = {
    form: PropTypes.shape().isRequired,
    submitting: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired
  };

  state = {
    // selectedUserType: undefined,
    stateChoices: undefined,
    practiceChoices: undefined
  };

  onCountryChange = e => {
    const selectedCountry = e.target.value;
    const newState = { stateChoices: undefined, practiceChoices: undefined };
    if (selectedCountry === "Australia") {
      newState.stateChoices = australianStates;
    } else if (selectedCountry === "USA") {
      newState.stateChoices = unitedStates;
    }
    this.setState(newState);
  };

  onStateChange = e => {
    const selectedState = e.target.value;
    const newState = { practiceChoices: undefined };
    if (practicesByState[selectedState]) {
      newState.practiceChoices = practicesByState[selectedState];
    }
    this.setState(newState);
  };

  // onUserTypeChange = e => {
  //   const selectedUserType = e.target.value;
  //   const newState = { selectedUserType };
  //   this.setState(newState);
  // };

  handleSubmit = e => {
    e.preventDefault();
    const {
      form: { validateFieldsAndScroll },
      onSubmit
    } = this.props;
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSubmit(values);
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      submitting
    } = this.props;
    const { stateChoices, practiceChoices } = this.state;
    // const { stateChoices, practiceChoices, selectedUserType } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        <strong>Country:</strong>
        <Form.Item>
          {getFieldDecorator("country", {
            rules: [
              {
                required: true,
                message: "Please answer this question to continue"
              }
            ]
          })(
            <Radio.Group onChange={this.onCountryChange}>
              {countries.map(c => (
                <Radio key={c} value={c}>
                  {c}
                </Radio>
              ))}
            </Radio.Group>
          )}
        </Form.Item>

        {stateChoices ? (
          <>
            <strong>State:</strong>
            <Form.Item>
              {getFieldDecorator("state", {
                rules: [
                  {
                    required: true,
                    message: "Please answer this question to continue"
                  }
                ]
              })(
                <Radio.Group onChange={this.onStateChange}>
                  {stateChoices.map(st => (
                    <Radio key={st} value={st}>
                      {st}
                    </Radio>
                  ))}
                </Radio.Group>
              )}
            </Form.Item>
          </>
        ) : null}

        {practiceChoices ? (
          <>
            <strong>Usual pathology practice:</strong>
            <Form.Item>
              {getFieldDecorator("practice", {
                rules: [
                  {
                    required: true,
                    message: "Please answer this question to continue"
                  }
                ]
              })(
                <Radio.Group>
                  {practiceChoices.map(pr => (
                    <Radio key={pr} value={pr}>
                      {fixPracticeName(pr)}
                    </Radio>
                  ))}
                </Radio.Group>
              )}
            </Form.Item>
          </>
        ) : null}

        <strong>I am a:</strong>
        <Form.Item>
          {getFieldDecorator("user_type", {
            rules: [
              {
                required: true,
                message: "Please answer this question to continue"
              }
            ]
          })(
            <Radio.Group onChange={this.onUserTypeChange}>
              {userTypes.map(ut => (
                <Radio key={ut} value={ut}>
                  {fixUserTypeName(ut)}
                </Radio>
              ))}
            </Radio.Group>
          )}
        </Form.Item>

        {/* {['GP', 'Specialist'].includes(selectedUserType) ? (
          <>
            <strong>Doctor code:</strong>
            <Form.Item>
              {getFieldDecorator('doctor_code', {
                rules: [
                  // {
                  //   required: true,
                  //   message: 'Please answer this question to continue',
                  // },
                ],
              })(<Input type="text" maxLength={32} />)}
            </Form.Item>
            <strong>Provider number:</strong>
            <Form.Item>
              {getFieldDecorator('provider_number', {
                rules: [
                  // {
                  //   required: true,
                  //   message: 'Please answer this question to continue',
                  // },
                ],
              })(<Input type="text" maxLength={32} />)}
            </Form.Item>
          </>
        ) : null} */}

        <PinnedToBottom>
          <div className="onboarding-footer">
            <NavButton
              disabled={submitting}
              loading={submitting}
              type="primary"
              htmlType="submit"
            >
              Next
            </NavButton>
          </div>
        </PinnedToBottom>
      </Form>
    );
  }
}

export default Form.create()(PersonaliseForm);
