import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import { Button } from '@liquid-state/ui-kit';
import { Container, HeaderImage } from '@common';
import { terms } from '../../assets';

const AcceptableForm = ({ onAgree, children }) => (
  <Container fixed noPadding className="onboarding toc">
    <HeaderImage imgUrl={terms} />
    <Card>
      {children}
      <div className="agree">
        <Button stretched type="primary" onClick={onAgree}>
          I agree
        </Button>
      </div>
    </Card>
  </Container>
);

AcceptableForm.propTypes = {
  onAgree: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default AcceptableForm;
