import React from "react";
import PropTypes from "prop-types";
import { Card, Col, Icon, Row } from "antd";
import { Button } from "@liquid-state/ui-kit";
import { Link } from "@common";

/* eslint-disable camelcase */
const DocumentCard = ({
  description,
  display_name,
  cover_url,
  product_id,
  getButtonTextForDocument
}) => {
  const parts = product_id.split(".");
  return (
    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
      <Link to={`/publications/document/${parts[parts.length - 1]}`}>
        <Card hoverable>
          <Row type="flex">
            <Col xs={6} className="cover">
              <img src={cover_url} alt={display_name} />
            </Col>
            <Col xs={18} className="details">
              <Row type="flex">
                <Col xs={17}>
                  <h2>{display_name}</h2>
                </Col>
                <Col>
                  {description && Array.isArray(description) ? (
                    description.map(desc => (
                      <p key={`${desc.slice(10)}`}>{desc}</p>
                    ))
                  ) : (
                    <p>{description}</p>
                  )}
                </Col>
                <Col>
                  <Button type="default">
                    {getButtonTextForDocument()}
                    <Icon type="right" className="accessory" />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Link>
    </Col>
  );
};
/* eslint-enable */

DocumentCard.propTypes = {
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  display_name: PropTypes.string.isRequired,
  product_id: PropTypes.string.isRequired,
  getButtonTextForDocument: PropTypes.func.isRequired
};

DocumentCard.defaultProps = {
  description: ""
};

export default DocumentCard;
