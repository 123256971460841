import { initialisation } from '@common';
import content from './content';
import search from './search';
import documentDetails from './documentDetails';
import documentDetailsRefreshing from './documentDetailsRefreshing';
import profile from './profile';
import settings from './settings';
import isOnline from './isOnline';

export default {
  content,
  search,
  documentDetails,
  documentDetailsRefreshing,
  isOnline,
  profile,
  settings,
  initialisation: initialisation.reducer,
};
