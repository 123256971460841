import React from 'react';
import PropTypes from 'prop-types';
// import { IWARouterContext } from './Router';

export default class WithRouter extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    const { children } = this.props;
    return <>{children}</>;
    // const { children } = this.props;
    // return (
    //   <IWARouterContext.Consumer>
    //     {router => {
    //       return React.Children.map(children, child =>
    //         React.cloneElement(child, { router }),
    //       );
    //     }}
    //   </IWARouterContext.Consumer>
    // );
  }
}
