import { SET_ONLINE_STATUS } from '../const';

export default (state = true, action) => {
  switch (action.type) {
    case SET_ONLINE_STATUS:
      return action.payload.isOnline;
    default:
      return state;
  }
};
