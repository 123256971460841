import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useLocation, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import { useEvaluatePermissions } from '@authorisation/hooks';
import { trackMainMenu } from '../../analytics';
import Divider from './Divider';

const MenuItem = ({
  name,
  path,
  icon,
  children,
  onClick = null,
  exact = false,
  subMenuItems = null,
  permissions = [],
}) => {
  const [toggled, setToggle] = useState(true);
  const [active, setActive] = useState(false);

  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    setActive(exact ? path === pathname : pathname.startsWith(path));
  }, [pathname, path, exact]);

  const permissionsOk = useEvaluatePermissions(...permissions);
  if (!permissionsOk) {
    return null;
  }

  return (
    <>
      <Divider />
      <div
        className={cn('menu-item', { active })}
        onClick={() => {
          if (active) {
            setToggle(!toggled);
          } else {
            setToggle(true);
          }
          trackMainMenu(name);
          if (onClick) {
            onClick();
          } else if (pathname !== path) {
            history.push(path);
          }
        }}
      >
        <div className="icon">{icon}</div>
        <span className="content">{children}</span>
        {subMenuItems && subMenuItems.length && (
          <span className="caret">
            <FontAwesomeIcon icon={!active || toggled ? faAngleDown : faAngleUp} />
          </span>
        )}
      </div>
      {toggled && active && subMenuItems
        ? subMenuItems
        : // subMenuItems.map(option => (
          //     <div
          //       className={cn('sub-menu-item', { active: option.active })}
          //       key={option.label}
          //       onClick={option.onClick}
          //     >
          //       {option.label}
          //     </div>
          //   ))
          null}
    </>
  );
};

MenuItem.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  exact: PropTypes.bool,
  permissions: PropTypes.array,
};

export default MenuItem;
