import { spawn } from "redux-saga/effects";
// import { faGlobe, faFlagAlt, faHospital } from '@fortawesome/pro-regular-svg-icons';
// import authorisation from '../authorisation/sagas';
// import registration from './registration/saga';
// import login from './obsoleteLogin/saga';
// import passwordRecovery from './passwordRecovery/saga';
// import onboarding from './onboarding/saga';
// import contentMessages from './contentMessages/sagas';
// import documents from './documents/sagas';
// import groups from './groups/saga';
// import appUsers from './appUsers/sagas';
// import messages from './messages/sagas';
// import forms from './forms/sagas';
// import myProfile from './myProfile/saga';
// import hospitals from './hospitals/sagas';
// import termsOfUse from './termsOfUse/saga';
// import dashboardUsers from './dashboardUsers/sagas';
// import weblinks from './weblinks/sagas';
// import indexEvents from '@pathways/redux/indexEvents/saga';
// import appUserPathways from '@pathways/redux/appUserPathways/sagas';
// import pathways from '@pathways/redux/pathways/sagas';
// import rules from '@pathways/redux/rules/sagas';
// import languages from '@utils/supportedLanguages';
// import { setLanguages, setOwners } from './core/actions';

import entry from "@entry/redux/sagas";
import home from "@home/redux/sagas";

export default function*() {
  // yield spawn(authorisation);
  yield spawn(entry);
  yield spawn(home);
  // yield spawn(registration);
  // yield spawn(login);
  // yield spawn(passwordRecovery);
  // yield spawn(onboarding);
  // yield spawn(contentMessages);
  // yield spawn(documents);
  // yield spawn(groups);
  // yield spawn(appUsers);
  // yield spawn(appUserPathways);
  // yield spawn(messages);
  // yield spawn(forms);
  // yield spawn(pathways);
  // yield spawn(rules);
  // yield spawn(indexEvents);
  // yield spawn(myProfile);
  // yield spawn(hospitals);
  // yield spawn(termsOfUse);
  // yield spawn(configureDashboard);
  // yield spawn(dashboardUsers);
  // yield spawn(weblinks);
}

// function* configureDashboard() {
//   yield put(setLanguages(Object.values(languages)));
//   yield put(
//     setOwners([
//       {
//         key: 'global',
//         name: 'common:source.global',
//         icon: faGlobe,
//       },
//       {
//         key: 'country',
//         name: 'common:source.country',
//         icon: faFlagAlt,
//       },
//       {
//         key: 'hospital',
//         name: 'common:source.hospital',
//         icon: faHospital,
//       },
//     ]),
//   );
// }
