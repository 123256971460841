import React from 'react';
import ReactDOM from 'react-dom';
import App from './pages';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { useTranslation } from 'react-i18next';
import { Router } from 'react-router';
import { ConfigProvider } from 'antd';
import enGB from 'antd/es/locale/en_GB';
// import deDE from 'antd/es/locale/de_DE';
// import esES from 'antd/es/locale/es_ES';
// import csCZ from 'antd/es/locale/cs_CZ';
import moment from 'moment';
import '@assets/fonts';
import createApp from '@liquid-state/iwa-core';
import Desktop, { middleware } from '@liquid-state/iwa-desktop';
import KeyValuePlugin from '@liquid-state/iwa-keyvalue';
import IdentityPlugin from '@liquid-state/iwa-identity';
import UbiqutyPlugin from '@liquid-state/ubiquity-client/dist/plugin';
import { AppContext } from '@common';
import 'moment/locale/en-gb';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/cs';
import { initialise as initialiseAnalytics, trackApplicationEvent } from './analytics';
import createStore from './store';
import definition from './webapp.json';
import * as serviceWorker from './serviceWorker';
import * as Settings from 'settings';
import './i18n';
import './style.less';

import { openPageReference } from './home/redux/actions';


const createDevApp = () => {
  const app = createApp(definition, Desktop.createNoopCommunicator());
  Desktop.injectDefaultMiddleware(app.communicator);
  app.communicator.addOnSendMiddleware(middleware.config(Settings));
  return app;
};
const instanciateApp = () => {
  const app = createDevApp();
  app.plugin(KeyValuePlugin.configure());
  app.plugin(IdentityPlugin.configure());
  app.plugin(UbiqutyPlugin.configure());
  return app;
};

const app = instanciateApp();
const history = createBrowserHistory();
const store = createStore({ app, history });

initialiseAnalytics();
trackApplicationEvent('Loading');

// maps i18next ISO language codes to antd locale codes
// const getLocale = lang => {
//   switch (true) {
//     case lang.startsWith('en'):
//       return enGB;
//     case lang.startsWith('de'):
//       return deDE;
//     case lang.startsWith('es'):
//       return esES;
//     case lang.startsWith('cs'):
//       return csCZ;
//     default:
//       return enGB;
//   }
// };

const Application = () => {
  const {
    i18n: { language },
  } = useTranslation();
  // const locale = useMemo(() => getLocale(language), [language]);
  const locale = enGB;

  moment.locale(language);

  return (
    <Provider store={store}>
      <ConfigProvider locale={locale}>
        <Router history={history}>
          <AppContext.Provider value={app}>
            <App />
          </AppContext.Provider>
        </Router>
      </ConfigProvider>
    </Provider>
  );
};

ReactDOM.render(<Application />, document.getElementById('root'));

if (!window.linksHandler) {
  window.linksHandler = function(data) {
    const { page_reference } = data;
    if (page_reference) {
      store.dispatch(openPageReference(page_reference));
    }
  }
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
