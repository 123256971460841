export const validatePasswordComplexity = (rule, value, callback) => {
  if (!value) return callback();

  if (value.length < 8) {
    return callback(
      'Your password must be at least eight characters long, and must include at least one number, one uppercase letter, and one lowercase letter.',
    );
  }
  // These should be extended with Unicode character classes.
  const numbers = /[0-9]/;
  const uppercase = /[A-Z]/;
  const lowercase = /[a-z]/;
  const valid =
    numbers.test(value) && uppercase.test(value) && lowercase.test(value);
  if (!valid) {
    return callback(
      'Your password must be at least eight characters long, and must include at least one number, one uppercase letter, and one lowercase letter.',
    );
  }

  return callback();
};

export const compareToFirstPassword = (firstPassword, value, callback) => {
  if (value && value !== firstPassword) {
    return callback('Your passwords do not match.');
  }

  return callback();
};
