import { takeEvery, getContext, call, put } from "redux-saga/effects";
import IdentityPlugin from "@liquid-state/iwa-identity";
import { getAuthenticator } from "@liquid-state/iwa-cognito-identity";
import KeyValuePlugin, { Key } from "@liquid-state/iwa-keyvalue";
import UISClient from "@common/uis";
import {
  LOGOUT,
  CHANGE_PASSWORD,
  DELETE_ACCOUNT,
  SEND_PERSONAL_DATA
} from "../const";
import { changePasswordFailure, changePasswordSuccess } from "../actions";

export default function*() {
  yield takeEvery(LOGOUT, onLogout);
  yield takeEvery(CHANGE_PASSWORD, onChangePassword);
  yield takeEvery(DELETE_ACCOUNT, doDeleteAccount);
  yield takeEvery(SEND_PERSONAL_DATA, doSendPersonalData);
}

function* onChangePassword(action) {
  const app = yield getContext("app");
  const idp = yield call(app.use.bind(app), IdentityPlugin);
  const cognito = idp.forService("cognito");
  const identity = yield call(cognito.getIdentity.bind(cognito));
  const authenticator = yield call(getAuthenticator, app);

  const { oldPassword, newPassword } = action.payload;

  try {
    yield call(
      authenticator.userChangePassword.bind(authenticator),
      oldPassword,
      newPassword,
      identity.name.replace("@", "*")
    );

    yield put(changePasswordSuccess());
  } catch (err) {
    console.error(err);

    yield put(changePasswordFailure(err));
  }
}

function* onLogout() {
  const app = yield getContext("app");
  const idp = app.use(IdentityPlugin);
  const kv = app.use(KeyValuePlugin);
  yield call(idp.logout.bind(idp));
  yield call(
    kv.set.bind(kv),
    new Key("home.favourites", [])
      .addWritePermission("iwa", "login")
      .addWritePermission("iwa", "entry")
      .addWritePermission("iwa", "home")
  );
  // yield call(app.communicator.send, Messages.app.reset());
  document.location.href = "/auth/login";
}

function* doDeleteAccount() {
  const app = yield getContext("app");
  const uis = UISClient(app);
  yield call(uis.deleteAccount);
  const idp = app.use(IdentityPlugin);
  yield call(idp.logout.bind(idp));
}

function* doSendPersonalData({ payload: { email } }) {
  const app = yield getContext("app");
  const uis = UISClient(app);
  yield call(uis.sendPersonalInformation, email);
}
