import {
  FAVOURITES_DELIMITER,
  FETCH_PROFILE,
  LOAD_PROFILE,
  TOGGLE_FAVOURITE,
  REFRESH_ALL_DOCUMENT_DETAILS_SUCCESS,
} from '../const';

export default (state = null, action) => {
  switch (action.type) {
    case FETCH_PROFILE:
      if (state === null) return { loading: true };

      return state;
    case LOAD_PROFILE:
      return { ...state, ...action.payload.profile };
    case REFRESH_ALL_DOCUMENT_DETAILS_SUCCESS:
      return { ...state, loading: false };

    case TOGGLE_FAVOURITE: {
      const {
        payload: { productId, pageSlug },
      } = action;
      const favouriteString = `${productId}${FAVOURITES_DELIMITER}${pageSlug}`;

      if (
        state &&
        state.favourites &&
        state.favourites.includes(favouriteString)
      ) {
        return {
          ...state,
          favourites: state.favourites.filter(f => f !== favouriteString),
        };
      }

      return { ...state, favourites: [...state.favourites, favouriteString] };
    }
    default:
      return state;
  }
};

export function mapFavouritesToPages(state) {
  if (
    !Object.keys(state.documentDetails).length ||
    !state.profile ||
    !state.profile.favourites ||
    state.profile.loading
  ) {
    return [];
  }

  const {
    documentDetails,
    profile: { favourites },
  } = state;

  return favourites
    .map(favouriteString => {
      if (favouriteString === '') return null;

      const [documentId, slug] = favouriteString.split(FAVOURITES_DELIMITER);

      if (!documentDetails[documentId]) return null;

      const {
        display_name: documentName,
        forDisplay: { pages },
      } = documentDetails[documentId];

      const page = pages.find(testPage => testPage.slug === slug);

      if (!page) return null;

      const { name, type, token } = page;

      return {
        documentId,
        documentName,
        slug,
        name,
        token,
        type,
        isFavourite: true,
      };
    })
    .filter(favourite => !!favourite);
}

export function checkIsFavourite(state, documentId, slug) {
  if (!state.profile || !state.profile.favourites) return false;

  return state.profile.favourites.includes(
    `${documentId}${FAVOURITES_DELIMITER}${slug}`,
  );
}
