import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Alert, Icon, Card } from "antd";
import { Container, HeaderImage } from "@common";
import { Button, TwoButtonModal, AlertModal } from "@liquid-state/ui-kit";
import { settings } from "../../assets";
import { deleteAccount, logout } from "../../redux/actions";

const DeleteAccount = ({ onDelete, onLogout }) => {
  const [visible, setVisible] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);

  return (
    <Container className="settings">
      <HeaderImage imgUrl={settings} />
      <Card>
        <h1>Delete my account and personal data</h1>
        <Alert
          type="warning"
          icon={<Icon type="exclamation-circle" />}
          showIcon
          message={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <>
              After deleting your account and personal data, you will no longer
              be able to use Sonic Edu. This action cannot be undone. If you
              want to review your personal data that has been collected by Sonic
              Edu, please create a request using the &lsquo;Send me a copy of my
              personal data&rsquo; option on the Settings page. Do you wish to
              continue with deleting your account and personal data?
            </>
          }
        />
        <Button stretched type="primary" onClick={() => setVisible(true)}>
          Delete my personal data
        </Button>
        <TwoButtonModal
          cancelText="Cancel"
          className="sonic-modal"
          okText="Delete"
          title="Are you sure you want to initiate deletion of all your personal data? This action cannot be undone."
          visible={visible}
          onCancel={() => setVisible(false)}
          onOk={() => {
            onDelete();
            setVisible(false);
            setAlertVisible(true);
          }}
        >
          <br />
        </TwoButtonModal>
        <AlertModal
          okText="Ok"
          className="sonic-modal"
          title="Request Submitted"
          visible={alertVisible}
          onOk={onLogout}
        >
          Once this request is processed, your account will be deleted along
          with your personal data. You will be contacted within 3 business days
          to notify the request is being processed. Meanwhile, your account is
          now disabled.
        </AlertModal>
      </Card>
    </Container>
  );
};

DeleteAccount.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired
};

export default connect(null, { onDelete: deleteAccount, onLogout: logout })(
  DeleteAccount
);
