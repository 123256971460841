import { put, spawn } from 'redux-saga/effects';
import { initialisation } from '@common';
import registration from './registration';
import session from './session';
import login from './login';
import passwordReset from './password-reset';

export default function*() {
  yield initialisation.saga();
  // Add your sagas here before the put(initialisation.initialise())
  yield spawn(session);
  yield spawn(registration);
  yield spawn(login);
  yield spawn(passwordReset);
  yield put(initialisation.initialise());
}
