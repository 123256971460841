import React from 'react';
import PropTypes from 'prop-types';
import { Container, ContentPadding } from '../layout';
import logo from './vials-animation.svg';
import './style.less';

const messages = [];

class ErrorTrap extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    forceRender: PropTypes.bool,
  };

  static defaultProps = {
    forceRender: false,
  };

  state = {
    error: null,
    showDetails: false,
  };

  componentDidCatch(error) {
    this.setState({ error });
  }

  renderError() {
    const { showDetails, error } = this.state;
    return (
      <Container fixed className="error-trap" onClick={this.trackClicks}>
        <ContentPadding>
          <h1>Oh no!</h1>
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <p>
            This application is still in development and it looks like something
            has gone wrong! Please restart your app to continue.
          </p>
          {showDetails && (
            <div>
              <p>Error Details: {error.toString() || 'Example details'}</p>
              <p>Stored messages:</p>
              {messages.map(message => (
                <p>{message}</p>
              ))}
            </div>
          )}
        </ContentPadding>
      </Container>
    );
  }

  render() {
    const { children, forceRender } = this.props;
    const { error } = this.state;

    return error || forceRender ? this.renderError() : children;
  }
}

const debug = message => messages.push(message);

export default ErrorTrap;

export { debug };
