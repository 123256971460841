export const TEST = "Hello";

export const deploymentName = "liquidstatecloud";
export const envName = "prod";

export const domain = "liquidstate.cloud";

// General App config
export const appToken = "e36599";
export const appId = 25;
export const ubiquityCompanyId = 16;
// "prod-forpatientapp-staff" Cognito User Pool
export const userPoolId = "ap-southeast-2_zDUw7Gj72";
export const userPoolAppClient = "63qtcec63jeskn62782mufavlp";

// IDM
export const idmOrganisation = 15;
export const organisationSlug = "sonic-australia";
export const idmApiRoot = `https://idm.${domain}/`;
export const organisationUrl = `${idmApiRoot}api/v1/organisations/`;
export const idmLogin = `https://idm.${domain}/api/login/`;
export const idmLogout = `https://idm.${domain}/logout/`;
// export const idmTokens = `https://idm.${domain}/api/tokens/`;

// CMS apps
export const carbonURL = `https://carbon.${domain}`;
export const ubiquityURL = `https://ubiquity.${domain}`;

// UIS
export const uisApiRoot = `https://uis.${domain}/`;

// PIP
export const pipApiRoot = `https://pip.${domain}`;
export const pipAppUrl = `https://pip.${domain}/api/admin/v1/apps/`;

export const ubiquityApiRoot = `https://ubiquity.${domain}/`;
export const pathwaysApiRoot = `https://pathways.${domain}/v1/apps/{{app_ubiquity_token}}/`;

export const messagingApiRoot = `https://ubiquity.${domain}/api/core/v1/apps/`;

export const videoCreationEndpoint = `https://unl5l2eu06.execute-api.ap-southeast-2.amazonaws.com/prod/create`;
export const videoExportStatusEndpoint = `https://unl5l2eu06.execute-api.ap-southeast-2.amazonaws.com/prod/export_status`;
export const videoCEDetails = {
  ce_space_uuid: "",
  ce_category_slug: "",
  template_name: ""
};
export const videoUbiquityExportDetails = {
  theme_slug: "default-theme",
  ubiquity_app_id: appId,
  ubiquity_company_id: ubiquityCompanyId,
  ubiquity_category_ids: [""]
};

export const mixpanelEnabled = false;
export const mixpanelToken = "";
export const mixpanelOptions = {
  // disable_persistence: true
  // api_host: "https://api-eu.mixpanel.com",
};
export const analyticsHiddenSuperProperties = ["firstName", "lastName", "name"];

export const metricsUrlRoot = "";

export const FEATURES = {
  help: {
    url: "https://example.com"
  }
};

export const envHasDataLake = false;

// settings for code ported from sonicuikit v3 mobile app (never released but developed fully functionally and some code of it was ported into this app):

const UBIQUITY_COMPANY_TOKEN = "0ec3b6";
const UBIQUITY_APP_TOKEN = appToken;
const DOMAIN_NAME = domain;
export const AWS_REGION = "ap-southeast-2";

export const app_token = UBIQUITY_APP_TOKEN;
export const company_token = UBIQUITY_COMPANY_TOKEN;
export const AWS_USER_POOL_ID = userPoolId;
export const AWS_USER_POOL_CLIENT_ID = userPoolAppClient;
export const UBIQUITY_BASE_URL = `https://ubiquity.${DOMAIN_NAME}/`;
export const UBIQUITY_BASE_S3_URL = `https://cms-${DOMAIN_NAME.replace(
  ".",
  "-"
)}.s3.${AWS_REGION}.amazonaws.com/`;
export const UBIQUITY_APP_USER_REGISTRATION_URL = `https://ubiquity.${DOMAIN_NAME}/api/appusers/v1/${UBIQUITY_APP_TOKEN}/register/`;
// if using a PIP, the following settings are needed:
export const UIS_URL = `https://uis.${DOMAIN_NAME}/`;
export const DOCSEARCH_URL = `https://docsearch.${DOMAIN_NAME}/`;
export const PIP_BASE_URL = `https://pip.${DOMAIN_NAME}`;
export const PIP_ID = "pip";
export const UBIQUITY_PIP_ID = "ubiquity";
export const DOCUMENT_SEARCH_PIP_ID = "document-search";
export const URL_CONTACT_US =
  "https://www.sonicpathology.com.au/contact-us/sonicedu/";
export const URL_MIXPANEL_DOCS =
  "https://help.mixpanel.com/hc/en-us/articles/115004613766-Default-Properties-Collected-by-Mixpanel";
export const URL_PRIVACY_POLICY =
  "https://www.sonicpathology.com.au/privacy-policy/sonicedu-privacy-policy/";
export const URL_TERMS_CONDITIONS =
  "https://www.sonicpathology.com.au/privacy-policy/sonicedu-terms-and-conditions/";
