import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
} from '../const';

const initialState = { loading: false, error: null };

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD:
      return { error: null, loading: true };
    case CHANGE_PASSWORD_FAILURE:
      return { error: action.payload.error, loading: false };
    case CHANGE_PASSWORD_SUCCESS:
      return { ...initialState };
    default:
      return state;
  }
};
