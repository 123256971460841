import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Form, Input } from 'antd';
import { Button } from '@liquid-state/ui-kit';
import { PinnedToBottom } from '@common';

const handleSubmit = (validateFieldsAndScroll, onSubmit) => e => {
  e.preventDefault();
  validateFieldsAndScroll((err, values) => {
    if (!err) {
      onSubmit(values);
    }
  });
};

const VerificationForm = ({
  error,
  form: { validateFieldsAndScroll, getFieldDecorator },
  loading,
  onResend,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(validateFieldsAndScroll, onSubmit)}>
    {error ? <Alert message={error} type="error" showIcon /> : null}
    <Form.Item label="Verification code">
      {getFieldDecorator('code', {
        rules: [
          {
            required: true,
            message: 'Your verification code is required to continue',
          },
          {
            len: 6,
            message: 'Your verification code should be 6 digits in length.',
          },
        ],
      })(
        <Input
          placeholder="Enter your verification code"
          pattern="\d*"
          type="number"
        />,
      )}
    </Form.Item>
    {/* eslint-disable-next-line */}
    <a onClick={onResend}>Resend code</a>
    <PinnedToBottom>
      <Button stretched type="primary" htmlType="submit" loading={loading}>
        Register
      </Button>
    </PinnedToBottom>
  </Form>
);

VerificationForm.propTypes = {
  error: PropTypes.string,
  form: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  onResend: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

VerificationForm.defaultProps = {
  error: '',
  loading: false,
};

export default Form.create()(VerificationForm);
