import React from "react";
import PropTypes from "prop-types";
import { Avatar, Icon, List } from "antd";
import { Button } from "@liquid-state/ui-kit";
// import { cold } from 'react-hot-loader';

// cold(List.Item.Meta);

const topicIcons = {
  concept: "file",
  topic: "folder",
  profile: "bars",
  test: "bar-chart"
};

const mapPageTypeToIcon = page => {
  if (page && page.type) {
    return topicIcons[page.type];
  }

  return "folder";
};

const PagesList = ({
  emptyText,
  pages,
  onFavouriteToggled,
  openPage,
  showDocumentName
}) => (
  <List
    className="pages-list"
    dataSource={pages}
    locale={{ emptyText }}
    rowKey="slug"
    renderItem={(page, pageIndex) => (
      <List.Item
        actions={[
          <Button
            className="icon-btn"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onFavouriteToggled(
                page.documentId,
                page.documentName,
                page.slug,
                page.name,
                page.type
              );
            }}
          >
            <Icon
              type="star"
              theme={page.isFavourite ? "filled" : "outlined"}
              className={page.isFavourite && "favourite"}
            />
          </Button>
        ]}
        className={showDocumentName ? "with-description" : null}
        onClick={() => openPage(page)}
      >
        <List.Item.Meta
          avatar={<Avatar className="type" icon={mapPageTypeToIcon(page)} />}
          title={page.name}
          description={showDocumentName ? page.documentName : null}
        />
      </List.Item>
    )}
  />
);

PagesList.propTypes = {
  emptyText: PropTypes.string,
  pages: PropTypes.array.isRequired,
  onFavouriteToggled: PropTypes.func.isRequired,
  openPage: PropTypes.func.isRequired,
  showDocumentName: PropTypes.bool
};

PagesList.defaultProps = {
  emptyText: "...",
  showDocumentName: false
};

export default PagesList;
