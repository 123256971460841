import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card } from 'antd';
import { Container, HeaderImage } from '@common';
import {
  registrationValidationSubmitted,
  resendRegistrationValidation,
} from '../../redux/actions/registration';
import { register } from '../../assets';

import Form from './Form';

import './styles.less';

const Verification = ({ error, loading, onResend, onSubmit }) => (
  <Container fixed noPadding className="onboarding verification">
    <HeaderImage imgUrl={register} />
    <Card>
      <h1>Verification</h1>
      <p>
        We&apos;ve sent a verification code to the email you entered in the
        previous step. Please check your email for the code and enter it here
        once you have received it.
      </p>
      <Form
        error={error}
        loading={loading}
        onResend={onResend}
        onSubmit={onSubmit}
      />
    </Card>
  </Container>
);

Verification.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  onResend: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

Verification.defaultProps = {
  error: '',
  loading: false,
};

export const VerificationForm = Verification;

const mapState = ({ registration }) => ({
  error: registration.error,
  loading: registration.submitting,
});

export default connect(
  mapState,
  {
    onResend: resendRegistrationValidation,
    onSubmit: registrationValidationSubmitted,
  },
)(VerificationForm);
