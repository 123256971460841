import React from 'react';
import { MediaButtonGroup } from '@liquid-state/ui-kit';

import './style.less';

const transform = ({ className, children, ...props }) => ({
  ...props,
  children: children.filter(x => x !== false),
  className: `${className} button-grid`,
});

const ButtonGrid = props => <MediaButtonGroup {...transform(props)} />;

export default ButtonGrid;
