import { takeEvery, getContext, call, put, spawn } from "redux-saga/effects";
import IdentityPlugin from "@liquid-state/iwa-identity";
import KeyValuePlugin, { Key } from "@liquid-state/iwa-keyvalue";
import { initialisation } from "@common";
import { SESSION_ESTABLISHED, sessionEstablished } from "../actions/session";
import { loginSucceeded } from "../actions/authentication";
import { setOnlineStatus } from "../actions/isOnline";
// import { loginFailed } from '../actions/authentication';

export default function* session() {
  yield takeEvery(SESSION_ESTABLISHED, sessionEstablishmentComplete);
  yield spawn(entry);
}

// const createMinimumDelay = (d = 3000) => {
//   const startTime = Date.now();
//   // eslint-disable func-names
//   return function*(action) {
//     const endTime = Date.now();
//     yield call(delay, d - (endTime - startTime));
//     yield action;
//   };
// };

/** Logic for the application entry
 *
 * Checks the following conditions
 * * Is this user currently logged in, if yes redirect to home.
 * * Has this device ever performed registration, if not show the registration page.
 * * Else show the login page
 */
function* entry() {
  // const minDelay = createMinimumDelay();
  yield call(initialisation.waitForInitialisation);

  yield put(setOnlineStatus(window.navigator.onLine));

  const app = yield getContext("app");
  const history = yield getContext("history");
  const idp = yield call(app.use.bind(app), IdentityPlugin);
  const cognito = idp.forService("cognito");
  let identity = { isAuthenticated: false };
  try {
    identity = yield call(cognito.getIdentity.bind(cognito));
  } catch (e) {
    // getIdentity throws if the kv is not initialised this should be addressed in core libraries.
  }

  if (identity.isAuthenticated) {
    yield put(loginSucceeded(identity.name, identity.credentials));
    yield put(sessionEstablished());
    return;
  }
  console.info("Not authenticated");
  const kv = yield call(app.use.bind(app), KeyValuePlugin);

  const hasDoneRegistration = yield call(
    kv.get.bind(kv),
    "app.has-done-registration"
  );
  if (hasDoneRegistration.decodeValue()) {
    localStorage.setItem(
      "post_auth_redirect",
      document.location.pathname + document.location.search
    );
    yield call(history.replace, "/auth/login");
  } else {
    const key = new Key("app.has-done-registration", true).addWritePermission(
      "iwa",
      "entry"
    );

    yield call(kv.set.bind(kv), key);
    yield call(history.replace, "/auth/registration");
  }
}

function* sessionEstablishmentComplete() {
  const app = yield getContext("app");
  const history = yield getContext("history");
  // const router = yield getContext("router");
  const idp = yield call(app.use.bind(app), IdentityPlugin);
  // let native app know we are authenticated
  try {
    yield call(idp.refreshAll.bind(idp));
  } catch (e) {
    history.replace("/auth/login");
    return;
  }

  // DISABLED redirection to / as we want URLs to be bookmarkable
  if (document.location.pathname === "/auth/login") {
    history.push("/");
  }
}
