import React from 'react';
import { Card } from 'antd';
import { Container, HeaderImage, TermsAndConditions } from '@common';
import { terms } from '@entry/assets';

export default ({ history }) => (
  <Container fixed noPadding className="onboarding toc">
    <HeaderImage imgUrl={terms} />
    <Card>
      <TermsAndConditions
        onPrivacyClicked={() => history.push('/settings/privacy-policy')}
      />
    </Card>
  </Container>
);
