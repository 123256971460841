import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import PagesList from './PagesList';

const pagesForSection = (section, pages) =>
  pages.filter(p => section.pages.includes(p.token));
const sectionsWithoutNull = sections =>
  sections.filter(section => section.name !== null);
const sectionsWithNull = sections =>
  sections.filter(section => section.name === null);

const SectionsList = ({ sections, pages, onFavouriteToggled, openPage }) => (
  <div className="sections-list">
    {sectionsWithNull(sections).map((section, sectionIndex) => {
      const sectionPages = pagesForSection(section, pages);
      if (!sectionPages.length) {
        return null;
      }
      return (
        <PagesList
          key={sectionIndex}
          pages={sectionPages}
          onFavouriteToggled={onFavouriteToggled}
          openPage={openPage}
        />
      );
    })}
    <Collapse bordered={false}>
      {/* eslint-disable react/no-array-index-key */}
      {sectionsWithoutNull(sections).map(section => {
        const sectionPages = pagesForSection(section, pages);
        if (!sectionPages.length) {
          return null;
        }
        return (
          <Collapse.Panel
            header={section.name}
            key={`section-${section.name}-${sectionPages.length}`}
          >
            <PagesList
              pages={sectionPages}
              onFavouriteToggled={onFavouriteToggled}
              openPage={openPage}
            />
          </Collapse.Panel>
        );
      })}
    </Collapse>
  </div>
);

SectionsList.propTypes = {
  sections: PropTypes.array.isRequired,
  pages: PropTypes.array.isRequired,
  onFavouriteToggled: PropTypes.func.isRequired,
  openPage: PropTypes.func.isRequired,
};

export default SectionsList;
