import React from "react";
import { Container, HeaderImage, Link } from "@common";
import { Button, MediaButton } from "@liquid-state/ui-kit";
import { Card, Icon } from "antd";
import { terms } from "../assets";

const ApplyForSourceCode = () => (
  <Container noPadding className="onboarding">
    <HeaderImage imgUrl={terms} />
    <Card>
      <h1>Registration help</h1>

      <p>
        The information contained within the Sonic Edu app is designed to
        support pathologists, clinicians and healthcare professionals. The use
        of a Source Code for registration helps us to ensure that app content is
        accessed by those in the healthcare environment.
      </p>

      <p>
        Please contact The Doctors Laboratory if you need help with
        registration.
      </p>

      <p>The Doctors Laboratory Ltd</p>
      <p>
        The Halo Building, 1 Mabledon Place
        <br />
        London, WC1H 9AX, UK
      </p>
      <a href="mailto:tdltestguide@tdlpathology.com">
        <MediaButton>
          <Icon type="envelope" />
          Email
        </MediaButton>
      </a>

      <Link to="/auth/registration" replace>
        <Button stretched type="primary">
          Register
        </Button>
      </Link>
    </Card>
  </Container>
);

export default ApplyForSourceCode;
