/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
import {
  all,
  call,
  getContext,
  put,
  select,
  takeEvery
} from "redux-saga/effects";
// import { Messages } from "@liquid-state/iwa-core";
import { ubiquity } from "@common";
import UbiqutyPlugin from "@liquid-state/ubiquity-client/dist/plugin";
import { REFRESH_CONTENT, OPEN_DOCUMENT, OPEN_PAGE_REFERENCE } from "../const";
import {
  fetchProfile,
  refreshContentSucceeded,
  refreshContentFailed,
  refreshedDocumentDetails,
  refreshAllDocumentDetailsSuccess,
  // setOnlineStatus,
  // deactivateDocumentSearch,
  openDocument
} from "../actions";

const mapDocument = document => ({
  ...document,
  dataUrl: document.version_url.replace(/versions\/\d+\//, "issue.json"),
  tocUrl: document.version_url.replace(/versions\/\d+\//, "toc/toc.json")
});

const mapContent = content => ({
  ...content,
  documents: Object.values(content.documents)
    .sort((a, b) => a.order_index - b.order_index)
    .map(mapDocument),
  documentNames: Object.values(content.documents).reduce(
    (acc, d) => ({ ...acc, [d.product_id]: d.display_name }),
    {}
  )
});

export default function* root() {
  yield takeEvery(REFRESH_CONTENT, doRefreshContent);
  yield takeEvery(OPEN_DOCUMENT, doOpenDocument);
  yield takeEvery(OPEN_PAGE_REFERENCE, doOpenPageReference);
}

const getJSON = url =>
  fetch(url).then(resp => {
    if (!resp.ok) {
      throw resp;
    }
    return resp.json();
  });

function* doRefreshContent({ payload }) {
  if (!payload.force) {
    const docs = yield select(state => state.content.documents);
    if (docs.length) {
      yield put(refreshAllDocumentDetailsSuccess());
      return;
    }
  }
  console.log("Refreshing documents");

  // yield call(initialisation.waitForInitialisation);
  const app = yield getContext("app");

  // yield put(setOnlineStatus(window.navigator.onLine));
  const profile = yield select(state => state.profile);

  try {
    if (profile === null) {
      yield put(fetchProfile());
    }
  } catch (e) {
    console.error(e);
    yield put(refreshContentFailed(e));
  }

  try {
    const ubiClient = yield call(app.use, UbiqutyPlugin);
    const content = yield call(ubiquity.refreshContent, app, ubiClient);
    const mappedContent = mapContent(content);

    yield put(refreshContentSucceeded(mappedContent));

    for (const document of mappedContent.documents) {
      const [issueJson, toc] = yield all([
        call(getJSON, document.dataUrl),
        call(getJSON, document.tocUrl)
      ]);
      yield put(refreshedDocumentDetails(document, issueJson, toc));
    }

    yield put(refreshAllDocumentDetailsSuccess());
  } catch (e) {
    console.error(e);
    yield put(refreshContentFailed(e));
  }
}

function* doOpenDocument({ payload: { page } }) {
  // const app = yield getContext('app');
  // app.communicator.send(Messages.launch.document(productId, pageSlug));
  const parts = page.documentId.split(".");
  let url = `/publications/document/${parts[parts.length - 1]}/${page.token}`;
  // const searchText = new URLSearchParams(document.location.search).get('search')?.trim();
  const searchText = yield select(state => state.search.searchText);

  if (searchText) {
    url += "?search=" + searchText;
  }
  // yield put(deactivateDocumentSearch());
  const history = yield getContext("history");
  history.push(url);
}

function* doOpenPageReference({ payload: { pageReference } }) {
  const [productId, pageSlug] = pageReference.split("/");
  // TODO: select page from store
  // const page = null;
  const pages = yield select(state => state.documentDetails[productId].pages);
  const page = pages.find(p => p.Slug === pageSlug);
  const pageToOpen = { documentId: productId, token: page.PageToken };
  yield put(openDocument(pageToOpen));
}
