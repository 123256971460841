import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TwoButtonModal } from "@liquid-state/ui-kit";
import { Container, ContentPadding } from "@common";
import LibraryHeader from "../../components/LibraryHeader";
import PagesList from "../Document/PagesList";
import SkeletonList from "../../components/SkeletonList";
import { mapFavouritesToPages } from "../../redux/reducers/profile";
import {
  openDocument as openDocumentAction,
  refreshContent,
  toggleFavourite
} from "../../redux/actions";

import "./styles.less";

export const Favourites = ({
  favouritePages,
  hasContent,
  isDocumentDetailsRefreshing,
  isLoading,
  onFavouriteToggled,
  openPage,
  refresh
}) => {
  const [isVisible, updateVisible] = useState(false);
  const [favouriteItemData, updateFavouriteItemData] = useState(null);

  useEffect(() => {
    if (!hasContent && !isLoading) {
      refresh();
    }
  });

  document.title = "Favourites - Sonic Edu";
  const isRefreshing = isDocumentDetailsRefreshing && !isLoading;

  return (
    <Container noPadding className="document-details favourites">
      <LibraryHeader src={null} />
      <ContentPadding>
        <h1>Favourites</h1>
        <p>Your favourited content list is below.</p>
        <SkeletonList active={isLoading || isRefreshing}>
          <PagesList
            emptyText="You have no favourites yet"
            pages={favouritePages}
            showDocumentName
            onFavouriteToggled={(...args) => {
              updateVisible(true);
              // store favourite data in local state for access in popup
              updateFavouriteItemData([...args]);
            }}
            openPage={openPage}
          />
        </SkeletonList>
      </ContentPadding>
      <TwoButtonModal
        cancelText="Cancel"
        className="sonic-modal"
        okText="Remove"
        title="Are you sure you want to unfavourite content?"
        visible={isVisible}
        onCancel={() => {
          updateVisible(false);
          updateFavouriteItemData(null);
        }}
        onOk={() => {
          onFavouriteToggled(...favouriteItemData);
          updateVisible(false);
          updateFavouriteItemData(null);
        }}
      >
        <br />
      </TwoButtonModal>
    </Container>
  );
};

Favourites.propTypes = {
  favouritePages: PropTypes.arrayOf(
    PropTypes.shape({
      documentId: PropTypes.string,
      slug: PropTypes.string,
      type: PropTypes.string,
      name: PropTypes.string,
      isFavourite: PropTypes.bool,
      token: PropTypes.string
    })
  ).isRequired,
  hasContent: PropTypes.bool.isRequired,
  isDocumentDetailsRefreshing: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  openPage: PropTypes.func.isRequired,
  onFavouriteToggled: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired
};

Favourites.defaultProps = {
  isLoading: false
};

const mapStateToProps = state => ({
  isDocumentDetailsRefreshing: state.documentDetailsRefreshing,
  favouritePages: mapFavouritesToPages(state),
  hasContent: !!state.content.documents.length,
  isLoading: state.profile && state.profile.loading
});

export default connect(mapStateToProps, {
  openPage: openDocumentAction,
  onFavouriteToggled: toggleFavourite,
  refresh: refreshContent
})(Favourites);
