import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, HeaderImage, Link, AppContext } from "@common";
import { useExternalLink } from "@common/linking";
import { MediaButton, TwoButtonModal } from "@liquid-state/ui-kit";
import { Card, Icon } from "antd";
import { logout as logoutAction } from "../../redux/actions";
import { settings } from "../../assets";

import "./styles.less";

const logout = cb => e => {
  e.preventDefault();
  e.stopPropagation();
  cb();
};

const Settings = ({ onLogout }) => {
  document.title = "Settings - Sonic Edu";
  const [isVisible, updateVisible] = useState(false);
  const app = useContext(AppContext);
  const externalLink = useExternalLink(app);
  return (
    <Container className="settings">
      <HeaderImage imgUrl={settings} />
      <Card>
        <h1>Settings</h1>
        <Link to="/settings/change-password">
          <MediaButton>
            <Icon type="key" />
            Change password
          </MediaButton>
        </Link>
        <MediaButton onClick={() => externalLink("URL_TERMS_CONDITIONS")}>
          <Icon type="file-text" />
          View terms and conditions
        </MediaButton>
        <MediaButton onClick={() => externalLink("URL_PRIVACY_POLICY")}>
          <Icon type="file-text" />
          View privacy policy
        </MediaButton>
        <Link to="/settings/delete-account">
          <MediaButton>
            <Icon type="idcard" />
            Delete my account and personal data
          </MediaButton>
        </Link>
        <Link to="/settings/send-data">
          <MediaButton>
            <Icon type="idcard" />
            Send me a copy of my personal data
          </MediaButton>
        </Link>
        <MediaButton onClick={() => updateVisible(true)}>
          <Icon type="lock" />
          Log out
        </MediaButton>
      </Card>
      <TwoButtonModal
        cancelText="Cancel"
        className="sonic-modal"
        okText="Logout"
        title="Are you sure you want to Logout?"
        visible={isVisible}
        onCancel={() => updateVisible(false)}
        onOk={logout(onLogout)}
      >
        <br />
      </TwoButtonModal>
    </Container>
  );
};

Settings.propTypes = {
  onLogout: PropTypes.func.isRequired
};

export default connect(null, { onLogout: logoutAction })(Settings);
export { Settings };
