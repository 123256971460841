// Note: these are production settings for testing purposes

const UBIQUITY_COMPANY_TOKEN = '0ec3b6';
const UBIQUITY_APP_TOKEN = '33f201';
const DOMAIN_NAME = 'liquidstate.cloud';
const AWS_REGION = 'ap-southeast-2';

export default {
  app_token: UBIQUITY_APP_TOKEN,
  company_token: UBIQUITY_COMPANY_TOKEN,
  AWS_USER_POOL_ID: 'ap-southeast-2_PWqGCMx2S',
  AWS_USER_POOL_CLIENT_ID: '4s3j7b1js2td4kaj4993vb92lp',
  // AWS_IDENTITY_POOL_ID: 'ap-southeast-2:474a21bc-af05-4e83-8659-7107a6f731de',
  AWS_REGION,
  UBIQUITY_BASE_URL: `https://ubiquity.${DOMAIN_NAME}/`,
  UBIQUITY_BASE_S3_URL: `https://cms-${DOMAIN_NAME.replace(
    '.',
    '-',
  )}.s3.${AWS_REGION}.amazonaws.com/`,
  UBIQUITY_APP_USER_REGISTRATION_URL: `https://ubiquity.${DOMAIN_NAME}/api/appusers/v1/${UBIQUITY_APP_TOKEN}/register/`,
  // if using a PIP, the following settings are needed:
  UIS_URL: `https://uis.${DOMAIN_NAME}/`,
  DOCSEARCH_URL: `https://docsearch.${DOMAIN_NAME}/`,
  PIP_BASE_URL: `https://pip.${DOMAIN_NAME}`,
  PIP_ID: 'pip',
  UBIQUITY_PIP_ID: 'ubiquity',
  DOCUMENT_SEARCH_PIP_ID: 'document-search',
  URL_CONTACT_US: 'https://www.sonicpathology.com.au/contact-us/sonicedu/',
  URL_MIXPANEL_DOCS:
    'https://help.mixpanel.com/hc/en-us/articles/115004613766-Default-Properties-Collected-by-Mixpanel',
};
