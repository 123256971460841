import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, HeaderImage } from "@common";
import { Card, Alert } from "antd";
import {
  loginSubmitted,
  resetLoginState
} from "../../redux/actions/authentication";
import { openWebsite } from "../../redux/actions/registration";

import Form from "./LoginForm";
import { login } from "../../assets";

import "./styles.less";

const reRegistrationDescription = (
  <span>
    All user registered for Sonic Edu prior to May 2023 will need to
    re-register. Please use the &lsquo;register now&rsquo; link below to create
    your new Sonic Edu account.
  </span>
);

class LoginForm extends Component {
  static propTypes = {
    email: PropTypes.string,
    error: PropTypes.string,
    inProgress: PropTypes.bool,
    cleanup: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onOpenWebsite: PropTypes.func.isRequired
  };

  static defaultProps = {
    email: "",
    error: null,
    inProgress: false
  };

  componentWillUnmount() {
    // const { cleanup } = this.props;
    // cleanup();
  }

  render() {
    const { email, error, inProgress, onSubmit, onOpenWebsite } = this.props;

    return (
      <Container fixed noPadding className="onboarding login">
        <HeaderImage imgUrl={login} />
        <Card>
          <h1>Login</h1>
          <Alert
            showIcon
            message="Important"
            description={reRegistrationDescription}
            type="warning"
          />
          <Form
            email={email}
            error={error}
            loading={inProgress}
            onSubmit={onSubmit}
            onOpenWebsite={onOpenWebsite}
          />
        </Card>
      </Container>
    );
  }
}

export { LoginForm };

const mapState = state => ({
  email: state.login.email,
  error: state.login.error,
  inProgress: state.login.inProgress
});

export default connect(mapState, {
  cleanup: resetLoginState,
  onSubmit: loginSubmitted,
  onOpenWebsite: openWebsite
})(LoginForm);
