import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WithRouter from './WithRouter';

class ScrollToTop extends Component {
  state = { prevPathname: null };

  static propTypes = {
    children: PropTypes.node.isRequired,
    router: PropTypes.shape({
      history: PropTypes.shape({
        action: PropTypes.string,
        location: PropTypes.shape({ pathname: PropTypes.string }),
      }),
    }).isRequired,
  };

  componentDidUpdate() {
    const {
      router: {
        history: {
          action,
          location: { pathname },
        },
      },
    } = this.props;
    const { prevPathname } = this.state;

    if (pathname !== prevPathname) {
      if (action !== 'POP') window.scrollTo(0, 0);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        prevPathname: pathname,
      });
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default props => (
  <WithRouter>
    <ScrollToTop {...props} />
  </WithRouter>
);
