import en from './en.json';
import es from './es.json';
import de from './de.json';
import cs from './cs.json';

export default {
  en,
  es,
  de,
  cs,
};
