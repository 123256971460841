import React from 'react';
import { Card } from 'antd';
import { Container, HeaderImage, PrivacyPolicy } from '@common';
import { terms } from '@entry/assets';

export default () => (
  <Container fixed noPadding className="onboarding toc">
    <HeaderImage imgUrl={terms} />
    <Card>
      <PrivacyPolicy />
    </Card>
  </Container>
);
