import React from 'react';
import {
  Container,
  PinnedToBottom,
  NavButton,
  Link,
  HeaderImage,
} from '@common';
import { Card } from 'antd';
import { value } from '../../assets';
import './styles.less';

export default () => (
  <Container fixed noPadding className="onboarding value">
    <HeaderImage imgUrl={value} />
    <Card>
      <h1>Expert knowledge</h1>
      <p>
        Here you&apos;ll find a practical, in-depth guide to pathology tests and
        clinical conditions.
      </p>
      <PinnedToBottom>
        <div className="onboarding-footer">
          <Link to="/auth/registration/personalise" replace>
            <NavButton stretched type="primary">
              Next
            </NavButton>
          </Link>
        </div>
      </PinnedToBottom>
    </Card>
  </Container>
);
