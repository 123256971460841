import { spawn } from "redux-saga/effects";
import content from "./content";
import search from "./search";
import settings from "./settings";
import profile from "./profile";
import general from "./general";

// import { ENABLE_NOTIFICATIONS } from '../const';

export default function*() {
  // yield initialisation.saga();
  // Add your sagas here before the put(initialisation.initialise())
  yield spawn(content);
  yield spawn(search);
  yield spawn(settings);
  yield spawn(profile);
  yield spawn(general);
  // yield put(initialisation.initialise());

  // yield takeLatest(ENABLE_NOTIFICATIONS, doEnableNotifications);
}

// function* doEnableNotifications() {
//   const app = yield getContext('app');
//   const message1 = {
//     domain: 'app',
//     eventType: 'set_authentication_status',
//     data: {
//       is_authenticated: true,
//     },
//   };
//   app.communicator.send(message1);
//   const message2 = {
//     domain: 'app',
//     eventType: 'set_notification_presentation_status',
//     data: {
//       is_ready: true,
//     },
//   };
//   app.communicator.send(message2);
// }
