import {
  PERSONALISATION_SUBMITTED,
  REGISTRATION_SUBMITTED,
  REGISTRATION_FAILED,
  REGISTRATION_SUCCESS,
  REGISTRATION_VALIDATION_REQUIRED,
  REGISTRATION_VALIDATION_SUBMITTED,
  REGISTRATION_VALIDATION_FAILED,
  STORE_REGISTRATION_DATA,
  TOU_ACCEPTED,
  PRIVACY_POLICY_ACCEPTED
} from "../actions/registration";

const initialState = {
  submitting: false,
  error: null,
  credentials: {
    email: "",
    password: "",
    confirm: ""
    // email: 'cyril+220805a@liquid-state.com',
  },
  personalisation: {},
  touAccepted: false,
  dataCollectionAccepted: false,
  acknowledgementAccepted: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PERSONALISATION_SUBMITTED:
      return {
        ...state,
        submitting: true,
        personalisation: {
          ...state.personalisation,
          ...action.payload
        }
      };

    case STORE_REGISTRATION_DATA:
      return {
        ...state,
        credentials: {
          ...state.credentials,
          email: action.payload.email,
          password: action.payload.password,
          confirm: action.payload.confirm
        },
        dataCollectionAccepted: action.payload.dataCollection,
        acknowledgementAccepted: action.payload.acknowledgement,
        touAccepted: action.payload.termsAndConditions
      };

    case REGISTRATION_SUBMITTED:
      return {
        ...state,
        submitting: true,
        error: null,
        credentials: {
          email: action.payload.email,
          password: action.payload.password
        },
        touAccepted: true,
        dataCollectionAccepted: true,
        acknowledgementAccepted: true
      };

    case REGISTRATION_FAILED:
      return {
        ...state,
        submitting: false,
        error: action.payload.error
      };

    case REGISTRATION_VALIDATION_REQUIRED:
      return { ...state, error: null, submitting: false };

    case REGISTRATION_VALIDATION_SUBMITTED:
      return { ...state, submitting: true, error: null };

    case REGISTRATION_VALIDATION_FAILED:
      return { ...state, submitting: false, error: action.payload.error };

    case REGISTRATION_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null
      };

    case TOU_ACCEPTED:
      return {
        ...state,
        touAccepted: true
      };

    case PRIVACY_POLICY_ACCEPTED:
      return {
        ...state,
        dataCollectionAccepted: true,
        acknowledgementAccepted: true
      };

    default:
      return state;
  }
};
