import React from 'react';
import { connect } from 'react-redux';
import {
  TermsAndConditions as TermsContent,
  PrivacyPolicy as PrivacyContent,
} from '@common';
import {
  touAccepted,
  privacyPolicyAccepted,
} from '../../redux/actions/registration';
import AcceptableForm from './AcceptableForm';

const TermsAndConditions = connect(
  null,
  { onAgree: touAccepted },
)(({ onAgree, history }) => (
  <AcceptableForm onAgree={onAgree}>
    <TermsContent
      onPrivacyClicked={() => history.push('/registration/privacy')}
    />
  </AcceptableForm>
));

const PrivacyPolicy = connect(
  null,
  { onAgree: privacyPolicyAccepted },
)(({ onAgree }) => (
  <AcceptableForm onAgree={onAgree}>
    <PrivacyContent />
  </AcceptableForm>
));

export { TermsAndConditions, PrivacyPolicy };
