import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Affix, Row } from "antd";
import { Container, ContentPadding, WithRouter } from "@common";
import { Button } from "@liquid-state/ui-kit";
import LibraryHeader from "../../components/LibraryHeader";
import SearchForm from "../../components/SearchForm";
import SkeletonList from "../../components/SkeletonList";
import PagesList from "../Document/PagesList";
import DocumentCard from "./DocumentCard";
import { updateSearchText } from "../../redux/actions";

import {
  refreshContent,
  deactivateDocumentSearch,
  openDocument as openDocumentAction,
  toggleFavourite
} from "../../redux/actions";

import "./style.less";
import { mapSearchResultsToPages } from "../../redux/reducers/search";

export class InformationPage extends Component {
  state = {
    initialSearchText: null
  };

  componentDidMount() {
    document.title = "Publications - Sonic Edu";
    const { searchType, onClearSearch } = this.props;
    if (searchType !== "global") {
      onClearSearch();
    }

    const { documents, refresh } = this.props;
    if (!Object.keys(documents).length) {
      refresh(true);
    }
  }

  componentDidUpdate(prevProps) {
    const { documents } = this.props;
    const searchParam = new URLSearchParams(document.location.search).get(
      "search"
    );
    const { initialSearchText } = this.state;
    if (searchParam && searchParam !== initialSearchText) {
      this.setState({ initialSearchText: searchParam.trim() });
    }

    if (
      initialSearchText &&
      Object.keys(prevProps.documents).length !== Object.keys(documents).length
    ) {
      const { onUpdateSearchText } = this.props;
      onUpdateSearchText(initialSearchText);
    }

    // if (Object.keys(prevProps.documents).length !== Object.keys(documents).length) {
    //   const searchText = new URLSearchParams(document.location.search).get('search');
    //   if (searchText) {
    //     const { onUpdateSearchText } = this.props;
    //     onUpdateSearchText(searchText.trim());
    //   }
    // }
  }

  onBack = () => {
    const { router, isSearchActive, onClearSearch } = this.props;

    if (isSearchActive) {
      onClearSearch();
    } else {
      router.back();
    }
  };

  onClearSearch = () => {
    const { onClearSearch } = this.props;
    window.scrollTo(0, 0);
    onClearSearch();
    const { history } = this.props;
    this.setState({ initialSearchText: "" });
    history.push(history.location.pathname);
  };

  onRefreshContent = () => {
    const { refresh } = this.props;
    window.scrollTo(0, 0);
    refresh(true);
  };

  /* eslint-disable  class-methods-use-this */
  getButtonTextForDocument(document) {
    if (
      document.document_metadata &&
      document.document_metadata["button-text"]
    ) {
      return document.document_metadata["button-text"];
    }

    /**
     * document.buttonText included in mock data from storybook.
     * not sure if this will be present in real data.
     * */
    return document.buttonText || "Open";
  }
  /* eslint-enable */

  render() {
    const {
      documents,
      onFavouriteToggled,
      openDocument,
      isRefreshing,
      isSearching,
      isSearchActive,
      searchResults,
      history
    } = this.props;

    const { initialSearchText } = this.state;

    const searchResultsLengthMessage =
      searchResults.length === 50
        ? "50 top results"
        : `${searchResults.length} results`;

    console.log(documents);

    return (
      <Container
        noPadding
        className={`information-page ${
          isSearchActive ? "document-details" : ""
        }`}
      >
        <Affix className="affix-xl" offsetTop={-120}>
          <LibraryHeader src={null}>
            <SearchForm
              history={history}
              isReady={!!Object.keys(documents).length}
              initialSearchText={initialSearchText}
            />
          </LibraryHeader>
        </Affix>
        <ContentPadding>
          <SkeletonList active={isRefreshing || isSearching}>
            {isSearchActive && (
              <div className="sections-list">
                <div className="results-header">
                  {!searchResults.length ? (
                    <p>No search result.</p>
                  ) : (
                    <p>{searchResultsLengthMessage} across all documents</p>
                  )}
                  <Button type="primary" onClick={this.onClearSearch}>
                    Clear
                  </Button>
                </div>
                <PagesList
                  pages={searchResults}
                  openPage={(document, pageSlug) =>
                    openDocument(document, pageSlug)
                  }
                  onFavouriteToggled={(...args) => onFavouriteToggled(...args)}
                  showDocumentName
                />
              </div>
            )}
            {!isSearchActive && (
              <Row gutter={16} type="flex">
                {Object.keys(documents).map(docId => (
                  <DocumentCard
                    key={docId}
                    {...documents[docId]}
                    getButtonTextForDocument={() =>
                      this.getButtonTextForDocument(documents[docId])
                    }
                  />
                ))}
              </Row>
            )}
          </SkeletonList>
          {!isRefreshing && !isSearching && !isSearchActive ? (
            <Button type="primary" onClick={this.onRefreshContent}>
              Refresh content
            </Button>
          ) : null}
        </ContentPadding>
      </Container>
    );
  }
}

InformationPage.propTypes = {
  isRefreshing: PropTypes.bool,
  isSearching: PropTypes.bool,
  isSearchActive: PropTypes.bool,
  searchType: PropTypes.string,
  documents: PropTypes.shape(),
  searchResults: PropTypes.array,
  router: PropTypes.shape(),
  refresh: PropTypes.func,
  search: PropTypes.func,
  onClearSearch: PropTypes.func,
  onFavouriteToggled: PropTypes.func,
  openDocument: PropTypes.func,
  onUpdateSearchText: PropTypes.func
};

InformationPage.defaultProps = {
  isRefreshing: false,
  isSearching: false,
  isSearchActive: false,
  searchType: "global",
  documents: {},
  searchResults: [],
  router: {},
  refresh: () => {},
  search: () => {},
  onClearSearch: () => {},
  onFavouriteToggled: () => {},
  openDocument: () => {},
  onUpdateSearchText: () => {}
};

const mapStateToProps = state => ({
  documents: state.documentDetails,
  isRefreshing: state.documentDetailsRefreshing,
  isSearching: state.search.isSearching,
  isSearchActive: state.search.isSearchActive,
  searchResults: mapSearchResultsToPages(state),
  searchType: state.search.searchType
  // remapping favourite properties so we can re-use the PagesList component
});

const ConnectedInformationPage = connect(mapStateToProps, {
  refresh: refreshContent,
  onClearSearch: deactivateDocumentSearch,
  openDocument: openDocumentAction,
  onFavouriteToggled: toggleFavourite,
  onUpdateSearchText: updateSearchText
})(InformationPage);

export default props => (
  <WithRouter>
    <ConnectedInformationPage {...props} />
  </WithRouter>
);
