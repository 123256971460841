import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Alert, Form, Input, Checkbox, Card, Icon, Collapse } from "antd";
import { AppContext } from "@common";
import { openExternalLink } from "@common/linking";
import { Button } from "@liquid-state/ui-kit";
import {
  Container,
  PinnedToBottom,
  Link,
  HeaderImage,
  validatePasswordComplexity,
  compareToFirstPassword
} from "@common";
import {
  registrationSubmitted,
  storeRegistrationData,
  openWebsite
} from "../../redux/actions/registration";
import { register } from "../../assets";

import "./styles.less";

const { Panel } = Collapse;

const customPanelStyle = {
  background: "#f7f7f7",
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: "hidden"
};

const passwordCriteria = (
  <small>
    <i>
      Your password must be at least eight characters long, and must include at
      least one number, one uppercase letter, and one lowercase letter.
    </i>
  </small>
);

class Register extends React.Component {
  static propTypes = {
    confirm: PropTypes.string,
    email: PropTypes.string,
    error: PropTypes.string,
    form: PropTypes.object.isRequired,
    history: PropTypes.shape({ push: PropTypes.func }).isRequired,
    loading: PropTypes.bool,
    match: PropTypes.shape({ path: PropTypes.string }).isRequired,
    password: PropTypes.string,
    termsAndConditionsAccepted: PropTypes.bool,
    dataCollectionAccepted: PropTypes.bool,
    acknowledgementAccepted: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onTouClicked: PropTypes.func.isRequired,
    onPrivacyPolicyClicked: PropTypes.func.isRequired,
    onOpenWebsite: PropTypes.func.isRequired
  };

  static defaultProps = {
    confirm: "",
    email: "",
    error: "",
    loading: false,
    password: "",
    termsAndConditionsAccepted: false,
    acknowledgementAccepted: false,
    dataCollectionAccepted: false
  };

  state = {
    confirmDirty: false
  };

  handleSubmit = e => {
    const {
      form: { validateFieldsAndScroll },
      onSubmit
    } = this.props;
    e.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSubmit(values);
      }
    });
  };

  validateWithConfirmPassword = (rule, value, callback) => {
    const {
      props: { form },
      state: { confirmDirty }
    } = this;
    if (value && confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    const { confirmDirty } = this.state;
    this.setState({ confirmDirty: confirmDirty || !!value });
  };

  dataCollectionAccepted = (_, value, callback) =>
    value ? callback() : callback("Invalid");

  acknowledgementAccepted = (_, value, callback) =>
    value ? callback() : callback("Invalid");

  termsAndConditionsAccepted = (_, value, callback) =>
    value ? callback() : callback("Invalid");

  consentToCollection = () => {
    const {
      form: { getFieldsValue },
      onPrivacyPolicyClicked
    } = this.props;
    onPrivacyPolicyClicked(getFieldsValue());
  };

  acceptAcknowledgement = () => {
    const {
      form: { getFieldsValue },
      onPrivacyPolicyClicked
    } = this.props;
    onPrivacyPolicyClicked(getFieldsValue());
  };

  readPrivacyPolicy = e => {
    e.preventDefault();
    openExternalLink(this.context, "URL_PRIVACY_POLICY");
  };

  // readPrivacyPolicy = () => {
  //   const {
  //     form: { getFieldsValue },
  //     history,
  //     match,
  //     onTouClicked,
  //   } = this.props;
  //   history.push(`${match.path}/privacy`);
  //   const values = getFieldsValue();
  //   onTouClicked(values);
  // };

  readTermsAndConditions = e => {
    e.preventDefault();
    openExternalLink(this.context, "URL_TERMS_CONDITIONS");
  };

  // readTermsAndConditions = e => {
  //   const {
  //     form: { getFieldsValue },
  //     history,
  //     match,
  //     onTouClicked,
  //   } = this.props;
  //   e.preventDefault();
  //   history.push(`${match.path}/tou`);
  //   const values = getFieldsValue();
  //   onTouClicked(values);
  // };

  checkSubmitDisabled = () => {
    const { form } = this.props;
    const errors = form.getFieldsError();
    const values = form.getFieldsValue();

    if (!Object.keys(errors).length || !Object.keys(values).length) {
      return true;
    }

    return Object.keys(errors).some(
      key => errors[key] !== undefined || !values[key]
    );
  };

  handleContactUs = e => {
    e.preventDefault();
    const { onOpenWebsite } = this.props;
    onOpenWebsite("URL_CONTACT_US");
  };

  handleMixpanelWebsite = e => {
    e.preventDefault();
    const { onOpenWebsite } = this.props;
    onOpenWebsite("URL_MIXPANEL_DOCS");
  };

  render() {
    const {
      confirm,
      email,
      error,
      form: { getFieldDecorator, getFieldValue },
      loading,
      password,
      dataCollectionAccepted,
      acknowledgementAccepted,
      termsAndConditionsAccepted
    } = this.props;

    return (
      <Container fixed noPadding className="onboarding register">
        <HeaderImage imgUrl={register} />
        <Card>
          <h1>Registration</h1>
          <Form onSubmit={this.handleSubmit}>
            {error ? <Alert message={error} type="error" showIcon /> : null}

            <Form.Item label="Email" hasFeedback>
              {getFieldDecorator("email", {
                initialValue: email,
                rules: [
                  {
                    required: true,
                    message:
                      "An email address is required, this will be your username for the app."
                  },
                  {
                    type: "email",
                    message: "A valid email address is required."
                  }
                ]
              })(
                <Input
                  type="email"
                  placeholder="Enter your email address"
                  prefix={<Icon type="user" />}
                />
              )}
            </Form.Item>
            <Form.Item label="Password" hasFeedback>
              {getFieldDecorator("password", {
                initialValue: password,
                rules: [
                  {
                    required: true,
                    message: "A password is required"
                  },
                  { validator: validatePasswordComplexity },
                  { validator: this.validateWithConfirmPassword }
                ]
              })(
                <Input
                  type="password"
                  placeholder="Enter your password"
                  prefix={<Icon type="lock" />}
                />
              )}
            </Form.Item>
            <Form.Item label="Confirm password" hasFeedback>
              {getFieldDecorator("confirm", {
                initialValue: confirm,
                rules: [
                  {
                    required: true,
                    message: "You need to confirm your password to continue"
                  },
                  {
                    validator: (rule, value, callback) =>
                      compareToFirstPassword(
                        getFieldValue("password"),
                        value,
                        callback
                      )
                  }
                ]
              })(
                <Input
                  type="password"
                  placeholder="Re-enter your password"
                  prefix={<Icon type="lock" />}
                  onBlur={this.handleConfirmBlur}
                />
              )}
            </Form.Item>

            <Alert closable message={passwordCriteria} type="warning" />

            <h4>Your data</h4>
            <p>
              Before you register for Sonic Edu, we want to make you aware of
              the data we collect, and how it is used. Your data will only be
              used by Sonic Healthcare staff and licensed technology providers,
              in order to optimise your experience with Sonic Healthcare
              products and services.
            </p>

            <Collapse
              bordered={false}
              // defaultActiveKey={['1']}
              expandIcon={({ isActive }) => (
                <Icon type="caret-right" rotate={isActive ? 90 : 0} />
              )}
            >
              <Panel
                header="Registration details"
                key="1"
                style={customPanelStyle}
              >
                <p>
                  In the following form, we will ask for some basic information
                  about you. We need this information so that we can set up your
                  Sonic Edu access. The information you provide may also help us
                  personalise your Sonic Edu experience in future, by sending
                  you documents and notifications related to your role,
                  location, or practice.
                </p>
              </Panel>
              <Panel header="Analytics" key="2" style={customPanelStyle}>
                <p>
                  We use a service called Mixpanel to help us collect analytics
                  about how Sonic Edu is used. This helps us optimise how we
                  design new versions of Sonic Edu, and decide on new content
                  and features that should be added to the app. We collect the
                  following data for this purpose:
                  <dl>
                    <dt>Location data</dt>
                    <dd>City, Region, Country, Time zone</dd>
                    <dt>Device data</dt>
                    <dd>
                      Device, Browser version, Operating system, Mixpanel
                      library, Screen height, Screen width, Last seen
                    </dd>
                    <dt>Analytics data</dt>
                    <dd>
                      Current URL, Initial referrer, Initial referring domain,
                      Referrer, Referring Domain, Search engine, Search keyword,
                      UTM parameters
                    </dd>
                    <dt>Registration data</dt>
                    <dd>
                      Any details you enter in this registration form may be
                      sent to Mixpanel
                    </dd>
                  </dl>
                  For more information, see the {/* eslint-disable-next-line */}
                  <a href="#" onClick={this.handleMixpanelWebsite}>
                    Mixpanel website
                  </a>
                </p>
              </Panel>
              <Panel
                header="Push notifications"
                key="3"
                style={customPanelStyle}
              >
                <p>
                  For users of our iOS or Android Sonic Edu App, we use a
                  service called OneSignal to send notifications to your app. We
                  collect the following data to optimise the sending of push
                  notifications, including allowing us to send personalised
                  notifications to your device:
                  <dl>
                    <dt>Location data</dt>
                    <dd>Time Zone, Country, Location</dd>
                    <dt>Device data</dt>
                    <dd>
                      Device OS, Device Rooted, Device Language, Push Status,
                      App Version, In App Purchases, Google Ad Id and IFV, Your
                      Application Identifier, Cellular Carrier, Device Model, IP
                      Address, web_auth and web_p256, Push Tokens
                    </dd>
                    <dt>Analytics data</dt>
                    <dd>
                      First Session Time, Last Session Time, Session Count,
                      Total Usage Duration
                    </dd>
                  </dl>
                </p>
              </Panel>
            </Collapse>

            <Form.Item className="toc-form-item">
              {getFieldDecorator("dataCollection", {
                valuePropName: "checked",
                initialValue: dataCollectionAccepted,
                rules: [
                  {
                    required: true,
                    message: "You must consent to data collection to continue"
                  },
                  {
                    validator: this.dataCollectionAccepted,
                    message: "You must consent to data collection to continue"
                  }
                ]
              })(
                <Checkbox
                  className="terms-and-conditions"
                  onClick={this.consentToCollection}
                >
                  I understand the data that Sonic Edu collects, and consent to
                  its collection.
                </Checkbox>
              )}
            </Form.Item>

            <Form.Item className="toc-form-item">
              {getFieldDecorator("acknowledgement", {
                valuePropName: "checked",
                initialValue: acknowledgementAccepted,
                rules: [
                  {
                    required: true,
                    message:
                      "You must consent to the acknowledgement collection to continue"
                  },
                  {
                    validator: this.acknowledgementAccepted,
                    message:
                      "You must consent to the acknowledgement collection to continue"
                  }
                ]
              })(
                <Checkbox
                  className="terms-and-conditions"
                  onClick={this.consentToCollection}
                >
                  I acknowledge the information provided in these resources has
                  been written for the Australian medical context.
                </Checkbox>
              )}
            </Form.Item>

            <Form.Item className="toc-form-item">
              {getFieldDecorator("termsAndConditions", {
                valuePropName: "checked",
                initialValue: termsAndConditionsAccepted,
                rules: [
                  {
                    required: true,
                    message:
                      "You must agree to the terms and conditions to continue"
                  },
                  {
                    validator: this.termsAndConditionsAccepted,
                    message:
                      "You must agree to the terms and conditions to continue"
                  }
                ]
              })(
                <Checkbox className="terms-and-conditions">
                  I accept the Sonic Edu {/* eslint-disable-next-line */}
                  <a role="navigation" onClick={this.readPrivacyPolicy}>
                    Privacy Policy
                  </a>{" "}
                  and&nbsp; {/* eslint-disable-next-line */}
                  <a role="navigation" onClick={this.readTermsAndConditions}>
                    Terms &amp; Conditions
                  </a>
                  .
                </Checkbox>
              )}
            </Form.Item>

            <PinnedToBottom>
              <Button
                className={this.checkSubmitDisabled() ? "disabled" : ""}
                stretched
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Register
              </Button>
            </PinnedToBottom>
            <p>
              Already have an account?{" "}
              <Link to="/auth/login" replace>
                Log in
              </Link>
            </p>
            <p>
              Having trouble registering?{" "}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#" onClick={this.handleContactUs}>
                Contact us.
              </a>
            </p>
          </Form>
        </Card>
      </Container>
    );
  }
}
Register.contextType = AppContext;

export const RegisterForm = Form.create()(Register);

const mapState = ({ registration }) => ({
  confirm: registration.credentials.confirm,
  email: registration.credentials.email,
  error: registration.error,
  loading: registration.submitting,
  password: registration.credentials.password,
  termsAndConditionsAccepted: registration.touAccepted,
  privacyPolicyAccepted: registration.privacyPolicyAccepted,
  dataCollectionAccepted: registration.dataCollectionAccepted,
  acknowledgementAccepted: registration.acknowledgementAccepted
});

export default connect(mapState, {
  onSubmit: registrationSubmitted,
  onTouClicked: storeRegistrationData,
  onPrivacyPolicyClicked: storeRegistrationData,
  onOpenWebsite: openWebsite
})(RegisterForm);
