import React, { useCallback, useState } from "react";
import { Icon, Popover, List, Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import waitForStoreUpdate from "@utils/waitForStoreUpdate";
import { logoColour } from "@assets";
import {
  faKey
  // faDatabase,
  // faUserSlash,
} from "@fortawesome/pro-regular-svg-icons";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
// import { logout, goToPlatformApp } from '@redux/obsoleteLogin/actions';
// import { hasValidSession, selectCurrentDashboardUser } from '@redux/obsoleteLogin/reducer';
import { logout } from "@entry/redux/actions/authentication";
import MenuItem from "./MenuItem";
import LanguageSelector from "./LanguageSelectorPopup";

const { confirm } = Modal;

export default () => {
  // const currentDashboardUser = useSelector(selectCurrentDashboardUser);
  // const currentDashboardUser = undefined;
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = visible => {
    setVisible(visible);
  };

  const showLogOutConfirm = useCallback(() => {
    confirm({
      title: t("logout:title"),
      icon: (
        <FontAwesomeIcon className="log-out-icon" icon={faExclamationCircle} />
      ),
      okText: t("logout:ok"),
      onOk() {
        dispatch(logout());
        return waitForStoreUpdate(store, state => [state.login]);
      }
    });
  }, [dispatch, store, t]);

  return (
    <div className="menu">
      <div className="logo">
        {/* <img src={logoWhite} alt="" /> */}
        {/* <img src={logoText} alt="Sonic Edu" width="100%" /> */}
        <img src={logoColour} alt="Sonic Edu" width="100%" />
      </div>
      <div className="menu-items">
        <MenuItem name="Home" path="/" icon={<Icon type="bank" />} exact>
          {t("common:Menu.home")}
        </MenuItem>
        <MenuItem
          name="Publications"
          path="/publications"
          // icon={<FontAwesomeIcon icon={faFileAlt} />}
          icon={<Icon type="book" />}
          permissions={[]}
        >
          {t("common:Menu.information.title")}
        </MenuItem>
        <MenuItem
          name="Favourites"
          path="/favourites"
          icon={<Icon type="star" />}
          permissions={[]}
        >
          {t("common:Menu.favourites.title")}
        </MenuItem>
        <MenuItem
          name="Settings"
          path="/settings"
          icon={<Icon type="setting" />}
          permissions={[]}
        >
          {t("common:Menu.settings.title")}
        </MenuItem>
      </div>
      <Popover
        placement="rightBottom"
        title={t("common:Menu.popover.title")}
        trigger="hover"
        content={
          <List
            className="user-details-popover-list"
            size="small"
            dataSource={[
              {
                icon: <Icon type="lock" />,
                title: t("common:Menu.popover.logout"),
                onClick: () => showLogOutConfirm()
              },
              {
                icon: <FontAwesomeIcon icon={faKey} />,
                title: t("common:Menu.popover.password"),
                onClick: () => history.push("/settings/change-password")
              },
              // {
              //   icon: <FontAwesomeIcon icon={faGlobe} />,
              //   title: t('common:Menu.popover.language'),
              //   rightIcon: <Icon type="right" className="change-language-menu" />,
              //   key: 'languages',
              // },
              {
                // icon: <FontAwesomeIcon icon={faFileAlt} />,
                icon: <Icon type="file-text" />,
                title: t("common:Menu.popover.termsnCondition"),
                onClick: () => history.push("/settings/terms-and-conditions")
              },
              {
                icon: <Icon type="file-text" />,
                title: t("common:Menu.popover.privacyPolicy"),
                onClick: () => history.push("/settings/privacy-policy")
              }
              // {
              //   icon: <FontAwesomeIcon icon={faDatabase} className="tab-item-icon" />,
              //   title: t('myProfile:Tabs.dataDownload'),
              //   onClick: () => history.push('/my-profile/gdpr-request-data'),
              // },
              // {
              //   icon: <FontAwesomeIcon icon={faUserSlash} className="tab-item-icon" />,
              //   title: t('myProfile:Tabs.dataDeletion'),
              //   onClick: () => history.push('/my-profile/gdpr-delete-data'),
              // },
              // {
              //   icon: <FontAwesomeIcon icon={faFile} className="tab-item-icon" />,
              //   title: t('myProfile:Tabs.carbonEditor'),
              //   // onClick: () => dispatch(goToPlatformApp('carbon')),
              // },
              // {
              //   icon: <FontAwesomeIcon icon={faGlobe} className="tab-item-icon" />,
              //   title: t('myProfile:Tabs.ubiquityDashboard'),
              //   // onClick: () => dispatch(goToPlatformApp('ubiquity')),
              // },
            ]}
            bordered={false}
            split={false}
            renderItem={item => {
              return item.key ? (
                <Popover
                  overlayClassName="change-languages"
                  content={<LanguageSelector />}
                  placement="rightBottom"
                  trigger="hover"
                  visible={visible}
                  onVisibleChange={handleVisibleChange}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                >
                  <List.Item>
                    {item.icon} {item.title} {item.rightIcon}
                  </List.Item>
                </Popover>
              ) : (
                <List.Item onClick={item.onClick}>
                  {item.icon} {item.title} {item.rightIcon}
                </List.Item>
              );
            }}
          />
        }
      >
        <div className="user-details">
          <Icon type="lock" />
          <span className="name">{t("common:Menu.popover.title")}</span>
          {/* <span className="name">{currentDashboardUser?.name}</span> */}
          <Icon type="right" />
        </div>
      </Popover>
    </div>
  );
};
