import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@liquid-state/ui-kit';
import { Form, Input, Icon, Alert, Card } from 'antd';
import { Container, Link, PinnedToBottom, HeaderImage } from '@common';
import { passwordResetPasswordSubmitted } from '../../redux/actions/authentication';
import { register } from '../../assets';

const FormItem = Form.Item;

class Code extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
  };

  static defaultProps = {
    error: undefined,
  };

  state = {
    confirmDirty: false,
  };

  componentDidUpdate(prevProps) {
    const { error } = this.props;
    if (!prevProps.error && error) {
      window.scrollTo(0, 0);
    }
  }

  getError() {
    const { error } = this.props;
    return error ? <Alert message={error} type="error" showIcon /> : null;
  }

  handleSubmit = event => {
    event.preventDefault();
    const { form, onSubmit } = this.props;
    form.validateFieldsAndScroll((error, values) => {
      if (!error) {
        onSubmit({ ...values });
      }
    });
  };

  validateWithConfirmPassword = (rule, value, callback) => {
    const { form } = this.props;
    const { confirmDirty } = this.state;
    if (value && confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  validateComplexity = (rule, value, callback) => {
    if (!value) {
      callback();
      return;
    }
    if (value.length < 8) {
      callback('Your password should be at least 8 characters in length');
      return;
    }
    // These should be extended with Unicode character classes.
    const numbers = /[0-9]/;
    const uppercase = /[A-Z]/;
    const lowercase = /[a-z]/;
    const valid =
      numbers.test(value) && uppercase.test(value) && lowercase.test(value);
    if (!valid) {
      callback(
        'Your password must be at least eight characters long, and must include at least one number, one uppercase letter, and one lowercase letter.',
      );
      return;
    }
    callback();
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Your passwords do not match.');
    } else {
      callback();
    }
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    const { confirmDirty } = this.state;
    this.setState({ confirmDirty: confirmDirty || !!value });
  };

  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    return (
      <Container fixed noPadding className="onboarding">
        <HeaderImage imgUrl={register} />
        <Card>
          <h1>Forgotten password</h1>
          <h5 className="recover-subheading">
            Input 6 digit verification code
          </h5>
          <p>Please enter your six digit authentication code.</p>
          <Form className="forgotten" onSubmit={this.handleSubmit}>
            {this.getError()}
            <FormItem hasFeedback>
              {getFieldDecorator('code', {
                rules: [
                  {
                    required: true,
                    message: 'A code is required to continue.',
                  },
                  { len: 6, message: 'Your code should be exactly 6 digits.' },
                ],
              })(
                <Input
                  placeholder="Enter your 6 digit code"
                  prefix={<Icon type="lock" />}
                  type="number"
                />,
              )}
            </FormItem>
            <Link to="/auth/recovery/1">Resend code</Link>
            <h5 className="recover-subheading">Input new password</h5>
            <p>Please enter your new password below.</p>

            <FormItem hasFeedback>
              {getFieldDecorator('password', {
                rules: [
                  { required: true, message: 'New password is required!' },
                  { validator: this.validateComplexity },
                  { validator: this.validateWithConfirmPassword },
                ],
              })(
                <Input
                  placeholder="Enter new password"
                  prefix={<Icon type="lock" />}
                  type="password"
                />,
              )}
            </FormItem>

            <FormItem hasFeedback>
              {getFieldDecorator('confirm', {
                rules: [
                  { required: true, message: 'Please confirm your password' },
                  { validator: this.compareToFirstPassword },
                ],
              })(
                <Input
                  type="password"
                  placeholder="Repeat your new password"
                  prefix={<Icon type="lock" />}
                  onBlur={this.handleConfirmBlur}
                />,
              )}
            </FormItem>
            <p>
              <small>
                Your password must be at least eight characters long, and must
                include at least one number, one uppercase letter, and one
                lowercase letter.
              </small>
            </p>
            <PinnedToBottom>
              <Button type="primary" htmlType="submit" stretched>
                Submit New Password
              </Button>
            </PinnedToBottom>
          </Form>
        </Card>
      </Container>
    );
  }
}

const actions = {
  onSubmit: passwordResetPasswordSubmitted,
};

const mapStateToProps = state => ({
  error: state.passwordReset.error,
});

export const RecoveryCode = Form.create()(Code);

export default connect(
  mapStateToProps,
  actions,
)(RecoveryCode);
