import {
  REFRESH_CONTENT,
  REFRESH_CONTENT_SUCCEEDED,
  REFRESH_CONTENT_FAILED,
  SEARCH_DOCUMENTS,
  SEARCH_DOCUMENTS_SUCCEEDED,
  SEARCH_DOCUMENTS_FAILED,
  DEACTIVATE_DOCUMENT_SEARCH,
  REFRESHED_DOCUMENT_DETAILS,
  OPEN_DOCUMENT,
  OPEN_PAGE_REFERENCE,
  TOGGLE_FAVOURITE,
  LOAD_FAVOURITES,
  FAVOURITES_LOADED,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
  LOGOUT,
  LOAD_PROFILE,
  OPEN_EXTERNAL_LINK,
  REFRESH_ALL_DOCUMENT_DETAILS_SUCCESS,
  SET_ONLINE_STATUS,
  UPDATE_SEARCH_TEXT,
  FETCH_PROFILE,
  DELETE_ACCOUNT,
  SEND_PERSONAL_DATA,
  ENABLE_NOTIFICATIONS,
} from './const';

export const refreshContent = force => ({
  type: REFRESH_CONTENT,
  payload: {
    force,
  },
});

export const refreshContentSucceeded = content => ({
  type: REFRESH_CONTENT_SUCCEEDED,
  payload: {
    content,
  },
});

export const refreshContentFailed = error => ({
  type: REFRESH_CONTENT_FAILED,
  payload: {
    error,
  },
});

export const searchDocuments = (query, documentIssueToken) => ({
  type: SEARCH_DOCUMENTS,
  payload: {
    query,
    documentIssueToken,
  },
});

export const searchDocumentsSucceeded = result => ({
  type: SEARCH_DOCUMENTS_SUCCEEDED,
  payload: {
    result,
  },
});

export const searchDocumentsFailed = error => ({
  type: SEARCH_DOCUMENTS_FAILED,
  payload: {
    error,
  },
});

export const deactivateDocumentSearch = () => ({
  type: DEACTIVATE_DOCUMENT_SEARCH,
  payload: {},
});

export const refreshedDocumentDetails = (document, issue, toc) => ({
  type: REFRESHED_DOCUMENT_DETAILS,
  payload: {
    document,
    issue,
    toc,
  },
});

export const openDocument = (page) => ({
  type: OPEN_DOCUMENT,
  payload: {
    page,
  },
});

export const openPageReference = (pageReference) => ({
  type: OPEN_PAGE_REFERENCE,
  payload: {
    pageReference,
  },
});

export const loadFavourites = () => ({
  type: LOAD_FAVOURITES,
  payload: {},
});

export const favouritesLoaded = favourites => ({
  type: FAVOURITES_LOADED,
  payload: {
    favourites,
  },
});

export const toggleFavourite = (
  productId,
  productName,
  pageSlug,
  pageName,
  pageType,
) => ({
  type: TOGGLE_FAVOURITE,
  payload: {
    productId,
    productName,
    pageSlug,
    pageName,
    pageType,
  },
});

export const logout = () => ({
  type: LOGOUT,
  payload: {},
});

export const onChangePassword = (oldPassword, newPassword) => ({
  type: CHANGE_PASSWORD,
  payload: { oldPassword, newPassword },
});

export const changePasswordFailure = error => ({
  type: CHANGE_PASSWORD_FAILURE,
  payload: { error },
});

export const changePasswordSuccess = () => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload: {},
});

export const fetchProfile = () => ({
  type: FETCH_PROFILE,
  payload: {},
});

export const loadProfile = profile => ({
  type: LOAD_PROFILE,
  payload: { profile },
});

export const openExternalLink = url => ({
  type: OPEN_EXTERNAL_LINK,
  payload: { url },
});

export const refreshAllDocumentDetailsSuccess = () => ({
  type: REFRESH_ALL_DOCUMENT_DETAILS_SUCCESS,
  payload: {},
});

export const setOnlineStatus = isOnline => ({
  type: SET_ONLINE_STATUS,
  payload: { isOnline },
});

export const updateSearchText = text => ({
  type: UPDATE_SEARCH_TEXT,
  payload: { text },
});

export const deleteAccount = () => ({
  type: DELETE_ACCOUNT,
  payload: {},
});

export const sendPersonalData = email => ({
  type: SEND_PERSONAL_DATA,
  payload: {
    email,
  },
});

export const enableNotifications = () => ({
  type: ENABLE_NOTIFICATIONS,
  payload: {},
});
