export const PERSONALISATION_SUBMITTED = 'PERSONALISATION_SUBMITTED';
export const REGISTRATION_SUBMITTED = 'REGISTRATION_SUBMITTED';
export const REGISTRATION_VALIDATION_REQUIRED =
  'REGISTRATION_VALIDATION_REQUIRED';
export const REGISTRATION_VALIDATION_SUBMITTED =
  'REGISTRATION_VALIDATION_SUBMITTED';
export const REGISTRATION_FAILED = 'REGISTRATION_FAILED';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_VALIDATION_FAILED = 'REGISTRATION_VALIDATION_FAILED';
export const REGISTRATION_VALIDATION_RESEND = 'REGISTRATION_VALIDATION_RESEND';

export const TOU_LINK_CLICKED = 'TOU_LINK_CLICKED';
export const TOU_ACCEPTED = 'TOU_ACCEPTED';
export const PRIVACY_POLICY_LINK_CLICKED = 'PRIVACY_POLICY_LINK_CLICKED';
export const PRIVACY_POLICY_ACCEPTED = 'PRIVACY_POLICY_ACCEPTED';

export const STORE_REGISTRATION_DATA = 'STORE_REGISTRATION_DATA';

export const OPEN_WEBSITE = 'OPEN_WEBSITE';

export const personalisationSubmitted = values => ({
  type: PERSONALISATION_SUBMITTED,
  payload: values,
});

export const registrationSubmitted = ({ code, email, password }) => ({
  type: REGISTRATION_SUBMITTED,
  payload: {
    code,
    email,
    password,
  },
});

export const registrationValidationRequired = () => ({
  type: REGISTRATION_VALIDATION_REQUIRED,
  payload: {},
});

export const registrationFailed = error => ({
  type: REGISTRATION_FAILED,
  payload: {
    error,
  },
});

export const registrationSuccess = () => ({
  type: REGISTRATION_SUCCESS,
  payload: {},
});

export const registrationValidationSubmitted = ({ code }) => ({
  type: REGISTRATION_VALIDATION_SUBMITTED,
  payload: {
    code,
  },
});

export const registrationValidationFailed = error => ({
  type: REGISTRATION_VALIDATION_FAILED,
  payload: {
    error,
  },
});

export const resendRegistrationValidation = () => ({
  type: REGISTRATION_VALIDATION_RESEND,
  payload: {},
});

export const onTouLinkClicked = () => ({
  type: TOU_LINK_CLICKED,
  payload: {},
});

export const touAccepted = () => ({
  type: TOU_ACCEPTED,
  payload: {},
});

export const privacyPolicyAccepted = () => ({
  type: PRIVACY_POLICY_ACCEPTED,
  payload: {},
});

export const storeRegistrationData = formValues => ({
  type: STORE_REGISTRATION_DATA,
  payload: { ...formValues },
});

export const openWebsite = url => ({
  type: OPEN_WEBSITE,
  payload: { url },
});
