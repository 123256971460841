import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Affix } from "antd";
import { Container, ContentPadding, WithRouter, Link } from "@common";
import { Button } from "@liquid-state/ui-kit";
import LibraryHeader from "../../components/LibraryHeader";
import SearchForm from "../../components/SearchForm";
import SkeletonList from "../../components/SkeletonList";
import { checkIsFavourite } from "../../redux/reducers/profile";
import { mapSearchResultsToPages } from "../../redux/reducers/search";
import {
  refreshContent,
  searchDocuments,
  deactivateDocumentSearch,
  openDocument as openDocumentAction,
  toggleFavourite,
  updateSearchText
} from "../../redux/actions";
import SectionsList from "./SectionsList";
import PagesList from "./PagesList";

// this is used to avoid a React warning about forward refs as LibraryHeader
// is used inside an Affix that passes a ref to it
const LibraryHeaderComponent = React.forwardRef((props, ref) => (
  <LibraryHeader innerRef={ref} {...props} />
));

// eslint-disable-next-line react/prefer-stateless-function
export class DocumentPage extends React.Component {
  state = {
    initialSearchText: null
  };

  constructor(props) {
    super(props);
    this.iframeRef = React.createRef();
  }

  componentDidMount() {
    // if (navigator.userAgent.indexOf('Android') !== -1) {
    //   const { router } = this.props;
    //   router.setBackOverride(this.onBack);
    // }
    const { doc, refresh } = this.props;
    if (!doc) {
      refresh();
    }
    // const { currentSearchText } = this.props;
    // if (currentSearchText) {
    //   this.setState({ initialSearchText });
    // }
  }

  componentDidUpdate(prevProps) {
    const { document: doc, pages, currentPageToken } = this.props;
    if (!doc || !pages.length) return "";

    const searchParam = new URLSearchParams(document.location.search).get(
      "search"
    );
    const { initialSearchText } = this.state;
    if (searchParam && searchParam !== initialSearchText) {
      this.setState({ initialSearchText: searchParam });
      if (doc && !prevProps.document) {
        const { onUpdateSearchText } = this.props;
        onUpdateSearchText(searchParam.trim());
      }
    }

    if (currentPageToken) {
      const page = pages.find(p => p.token === currentPageToken);
      if (!page) return "";
      this.openPage(page);
      return;
    }
    // no page token specified so, by default, redirect to first page
    const { history } = this.props;
    // const urlProductId = doc.productId.replace(/\./g, '-');
    const parts = doc.productId.split(".");
    const urlProductId = parts[parts.length - 1];
    const location = document.location.pathname.replace(
      urlProductId,
      `${urlProductId}/${pages[0].token}`
    );
    history.replace(location);
    // document.location.href = location;
  }

  onBack = () => {
    const { isSearchActive, router, onClearSearch } = this.props;
    if (isSearchActive) {
      return onClearSearch();
    }
    return router.back();
  };

  onClearSearch = () => {
    const { onClearSearch } = this.props;
    window.scrollTo(0, 0);
    onClearSearch();
    const { history } = this.props;
    this.setState({ initialSearchText: "" });
    history.push(history.location.pathname);
  };

  getPageURL = (document, page) => {
    return `${document.versionURL}published/${page.index
      .toString()
      .padStart(4, "0")}-${page.token}-${page.slug}/index.html`;
  };

  getPageURLFromToken = pageToken => {
    const { document, pages } = this.props;
    if (!document || !pages.length) return "";
    const page = pages.find(p => p.token === pageToken);
    if (!page) return "";
    const pageURL = this.getPageURL(document, page);
    return pageURL;
  };

  openPage = page => {
    document.title = page.name + " - Sonic Edu";
    const { currentPageToken } = this.props;
    if (currentPageToken === page.token) {
      return;
    }

    // const { openDocument } = this.props;
    // openDocument(page);

    // update URL
    const { history } = this.props;
    const newLocation =
      history.location.pathname.replace(currentPageToken, page.token) +
      history.location.search;
    history.push(newLocation);
  };

  toggle = (...args) => {
    const { onFavouriteToggled } = this.props;
    onFavouriteToggled(...args);
  };

  goToPreviousPage = () => {
    // const { document: doc, pages, currentPageToken } = this.props;
    const { pages, currentPageToken } = this.props;
    const currentPage = pages.find(p => p.token === currentPageToken);
    if (currentPage.index > 0) {
      const newIndex = currentPage.index - 1;
      const { history } = this.props;
      // const urlProductId = doc.productId.replace(/\./g, "-");
      const location = document.location.pathname.replace(
        currentPageToken,
        `${pages[newIndex].token}`
      );
      history.push(location);
    }
  };

  goToNextPage = () => {
    // const { document: doc, pages, currentPageToken } = this.props;
    const { pages, currentPageToken } = this.props;
    const currentPage = pages.find(p => p.token === currentPageToken);
    if (currentPage.index < pages.length - 1) {
      const newIndex = currentPage.index + 1;
      const { history } = this.props;
      // const urlProductId = doc.productId.replace(/\./g, "-");
      const location = document.location.pathname.replace(
        currentPageToken,
        `${pages[newIndex].token}`
      );
      history.push(location);
    }
  };

  render() {
    const {
      document,
      pages,
      sections,
      search,
      onClearSearch,
      isSearchActive,
      isSearching,
      searchResults,
      currentSearchText,
      currentPageToken,
      // t,
      history
    } = this.props;

    const { initialSearchText } = this.state;

    const searchResultsLengthMessage =
      searchResults.length === 50
        ? "50 top results"
        : `${searchResults.length} results`;

    return (
      <Container noPadding className="document-details">
        <Affix className="affix-xl" offsetTop={-120}>
          <LibraryHeaderComponent src={null}>
            {/* {document ? */}
            <SearchForm
              history={history}
              isReady={!!document}
              initialSearchText={initialSearchText}
              isSearchActive={isSearchActive}
              onSearch={search}
              onClearSearch={onClearSearch}
              documentIssueToken={document?.issueToken ?? ""}
            />
            {/* : null} */}
          </LibraryHeaderComponent>
        </Affix>
        {/* <SubNavMenuContainer menu={
          <SubNavMenu>
            <Menu.Item key={'/page1'}>
              {t('patients:IndividualDetail.tabs.overview')}
            </Menu.Item>
          </SubNavMenu>}
        /> */}
        <Container noPadding>
          <ContentPadding>
            <h2>{document ? document.name : "Loading"}</h2>
            {document && document.description ? (
              <p>{document.description}</p>
            ) : null}

            {/* search results */}
            {isSearchActive && searchResults && (
              <SkeletonList active={isSearching}>
                <div className="results-header">
                  {!searchResults.length ? (
                    <p>No search result in this document.</p>
                  ) : (
                    <>
                      <p>
                        {searchResultsLengthMessage} in this document
                        <br />
                        <Link to={`/publications?search=${currentSearchText}`}>
                          Search in all documents
                        </Link>
                      </p>
                    </>
                  )}
                  <Button type="primary" onClick={this.onClearSearch}>
                    Clear
                  </Button>
                </div>
                <div style={{ width: "100%", overflowY: "auto" }}>
                  <div className="sections-list" style={{ width: "100%" }}>
                    <PagesList
                      pages={searchResults}
                      onFavouriteToggled={this.toggle}
                      openPage={this.openPage}
                    />
                  </div>
                </div>
              </SkeletonList>
            )}

            {/* TOC with sections */}
            {!isSearchActive && sections && sections.length ? (
              <div style={{ width: "100%", overflowY: "auto" }}>
                <SectionsList
                  sections={sections}
                  pages={pages}
                  onFavouriteToggled={this.toggle}
                  openPage={this.openPage}
                />
              </div>
            ) : null}

            {/* TOC without sections */}
            {!isSearchActive && (!sections || !sections.length) ? (
              <PagesList
                pages={pages}
                onFavouriteToggled={this.toggle}
                openPage={this.openPage}
              />
            ) : null}
            {document && !isSearchActive ? (
              <div className="page-controls">
                <Button onClick={this.goToPreviousPage}>Prev</Button>
                <Button onClick={this.goToNextPage}>Next</Button>
              </div>
            ) : null}
          </ContentPadding>
          {/* {document ? */}
          <iframe
            key={currentPageToken}
            ref={this.iframeRef}
            id="content-page"
            name="content-page"
            title="content"
            src={this.getPageURLFromToken(currentPageToken)}
          ></iframe>
          {/* : null
          } */}
        </Container>
      </Container>
    );
  }
}

DocumentPage.propTypes = {
  isSearching: PropTypes.bool,
  isSearchActive: PropTypes.bool,
  currentSearchText: PropTypes.string,
  searchResults: PropTypes.array,
  document: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string
  }),
  pages: PropTypes.array,
  sections: PropTypes.array,
  router: PropTypes.shape(),
  onFavouriteToggled: PropTypes.func,
  openDocument: PropTypes.func,
  search: PropTypes.func,
  onClearSearch: PropTypes.func,
  currentPageToken: PropTypes.string,
  onUpdateSearchText: PropTypes.func
};

DocumentPage.defaultProps = {
  isSearching: false,
  isSearchActive: false,
  currentSearchText: null,
  searchResults: [],
  document: null,
  pages: [],
  sections: [],
  onFavouriteToggled: () => {},
  openDocument: () => {},
  search: () => {},
  onClearSearch: () => {},
  onUpdateSearchText: () => {}
};

const mapState = (state, ownProps) => {
  const {
    match: {
      params: { productId: documentToken, pageToken }
    }
  } = ownProps;
  // const productId = mappedProductId.replace(/-/g, '.');
  const productId = Object.keys(state.documentDetails).find(
    pid => pid.indexOf(documentToken) > -1
  );
  const document = state.documentDetails[productId];
  const stateProps = {
    isSearching: state.search.isSearching,
    isSearchActive: state.search.isSearchActive,
    currentSearchText: state.search.searchText,
    searchResults: mapSearchResultsToPages(state),
    document: undefined,
    pages: [],
    sections: [],
    currentPageToken: pageToken
  };
  if (document) {
    // doc is already loaded in redux
    stateProps.document = {
      name: document.display_name,
      description: document.description,
      productId: document.product_id,
      issueToken: document.token,
      versionURL: document.version_url
    };
    stateProps.pages = document.forDisplay.pages.map((p, index) => ({
      ...p,
      isFavourite: checkIsFavourite(state, productId, p.slug),
      documentId: document.product_id,
      documentName: document.display_name
    }));
    stateProps.sections = document.forDisplay.sections;
    // stateProps.currentPageToken = pageToken ? document.forDisplay.pages.find(p => p.token === pageToken) : undefined;
  }
  return stateProps;
};

const actions = {
  refresh: refreshContent,
  search: searchDocuments,
  onClearSearch: deactivateDocumentSearch,
  openDocument: openDocumentAction,
  onFavouriteToggled: toggleFavourite,
  onUpdateSearchText: updateSearchText
};

const ConnectPage = connect(mapState, actions)(DocumentPage);

export default withTranslation()(props => (
  <WithRouter>
    <ConnectPage {...props} />
  </WithRouter>
));
