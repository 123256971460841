import {
  getContext,
  // takeLatest,
  takeEvery,
  put,
  call,
  select,
} from 'redux-saga/effects';

// import { delay } from 'redux-saga';
// import { Messages } from '@liquid-state/iwa-core';
// import KeyValuePlugin from '@liquid-state/iwa-keyvalue';
import IdentityPlugin from '@liquid-state/iwa-identity';
import { /* ubiquity, */ initialisation } from '@common';
import { SEARCH_DOCUMENTS } from '../const';
import {
  searchDocumentsSucceeded /* , searchDocumentsFailed */,
} from '../actions';

// import mockResult from '../../mock/search';

export default function* root() {
  yield takeEvery(SEARCH_DOCUMENTS, doSearchDocuments);
}

function* testDocSearchFTS(lowercaseQuery, documentId) {
  const app = yield getContext('app');
  // eslint-disable-next-line camelcase
  const { DOCSEARCH_URL, app_token } = yield call(
    app.configuration,
    'DOCSEARCH_URL',
    'app_token',
  );
  // eslint-disable-next-line camelcase
  let url = `${DOCSEARCH_URL}query/?app=${app_token}&term=${lowercaseQuery}`;
  if (documentId && documentId.length) {
    url += `&doc_id=${documentId}`;
  }
  const plugin = yield call(app.use, IdentityPlugin);
  const provider = plugin.forService('document-search');
  const identity = yield call(provider.getIdentity.bind(provider));
  const { jwt } = identity.credentials;
  const headers = {
    Authorization: `Bearer ${jwt}`,
  };
  const res = yield call(fetch, url, { headers });
  const data = yield call([res, 'json']);
  return data;
}

function* doSearchDocuments(action) {
  yield call(initialisation.waitForInitialisation);

  const { documentIssueToken, query } = action.payload;
  const lowercaseQuery = query.toLowerCase();
  const state = yield select();

  const toSearch = documentIssueToken
    ? [
        Object.values(state.documentDetails).find(
          document => document.token === documentIssueToken,
        ),
      ]
    : Object.values(state.documentDetails);

  let ftsResults = null;
  if (toSearch && toSearch.length === 1) {
    ftsResults = yield call(
      testDocSearchFTS,
      lowercaseQuery,
      toSearch[0].product_id,
    );
  } else {
    ftsResults = yield call(testDocSearchFTS, lowercaseQuery);
  }

  const results = ftsResults.results.map(result => {
    const docDetails = state.documentDetails[result.doc_id];
    return {
      fields: {
        document_name: docDetails ? docDetails.display_name : undefined,
        page_slug: result.slug,
        product_id: result.doc_id,
        title: result.title,
      },
      highlights: {},
      id: `${result.doc_id}${result.slug}`,
    };
  });

  /* eslint-enable camelcase */
  yield put(searchDocumentsSucceeded({ hits: results }));
}
