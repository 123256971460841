import {
  SEARCH_DOCUMENTS,
  SEARCH_DOCUMENTS_FAILED,
  SEARCH_DOCUMENTS_SUCCEEDED,
  DEACTIVATE_DOCUMENT_SEARCH,
  UPDATE_SEARCH_TEXT,
} from '../const';

import { checkIsFavourite } from './profile';

const initialState = Object.freeze({
  hits: [],
  isSearching: false,
  isSearchActive: false,
  searchText: '',
  searchType: 'global',
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_DOCUMENTS:
      return {
        ...state,
        isSearching: true,
        isSearchActive: true,
        searchType: action.payload.documentIssueToken ? 'document' : 'global',
      };

    case UPDATE_SEARCH_TEXT:
      return { ...state, searchText: action.payload.text };

    case SEARCH_DOCUMENTS_FAILED:
      return { ...state, isSearching: false };

    case SEARCH_DOCUMENTS_SUCCEEDED:
      return { ...state, ...action.payload.result, isSearching: false };

    case DEACTIVATE_DOCUMENT_SEARCH:
      return {
        ...state,
        searchText: '',
        isSearching: false,
        isSearchActive: false,
      };

    default:
      return state;
  }
};

export const mapSearchResultsToPages = state =>
  // temp fix untill bug with search addressed
  state.search.hits.reduce((prev, result) => {
    if (!result.fields.document_name) return prev;

    const pageInDocument = state.documentDetails[result.fields.product_id].forDisplay.pages.find(p => p.slug === result.fields.page_slug);

    return [
      ...prev,
      {
        index: pageInDocument.index,
        token: pageInDocument.token,
        name: result.fields.title,
        slug: result.fields.page_slug,
        type: 'topic',
        documentId: result.fields.product_id,
        documentName: result.fields.document_name,
        isFavourite: checkIsFavourite(
          state,
          result.fields.product_id,
          result.fields.page_slug,
        ),
      },
    ];
  }, []);
