import { takeEvery, call, getContext, put, select } from 'redux-saga/effects';
import { initialisation } from '@common';
import UbiquityPlugin from '@liquid-state/ubiquity-client/dist/plugin';
import { FETCH_PROFILE, TOGGLE_FAVOURITE } from '../const';
import { loadProfile } from '../actions';

export default function*() {
  yield takeEvery(FETCH_PROFILE, onFetchProfile);
  yield takeEvery(TOGGLE_FAVOURITE, onToggleFavourite);
}

function* onFetchProfile() {
  yield call(initialisation.waitForInitialisation);

  const app = yield getContext('app');
  const ubiquityClient = yield call(app.use, UbiquityPlugin);

  const profile = yield call(ubiquityClient.getProfile);

  if (!profile.favourites) profile.favourites = [];

  yield put(loadProfile(profile));
}

function* onToggleFavourite() {
  const app = yield getContext('app');
  const profile = yield select(state => state.profile);

  const ubiquityClient = yield call(app.use, UbiquityPlugin);

  if (profile.favourites.length > 0) {
    yield call(ubiquityClient.setProfile, { ...profile });
  } else {
    // work around for ubiquity not accepting empty arrays
    yield call(ubiquityClient.setProfile, { ...profile, favourites: [''] });
  }
}
