import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';
import { Route } from '@common';

import Email from './Email';
import Code from './Code';

const Recovery = ({ match }) => (
  <Switch>
    <Route
      exact
      path={`${match.path}`}
      render={() => <Redirect to="/auth/recovery/1" />}
    />
    <Route path={`${match.path}/1`} component={Email} exact />
    <Route path={`${match.path}/2`} component={Code} exact />
  </Switch>
);

Recovery.propTypes = {
  match: PropTypes.shape({ path: PropTypes.string }).isRequired,
};

export default Recovery;
