import React, { useState, useEffect } from 'react';
import { Pager } from '@liquid-state/ui-kit';
import { loading } from '../assets';

export default () => {
  const [selected, setSelected] = useState(0);
  useEffect(() => {
    const id = setTimeout(() => {
      setSelected((selected + 1) % 5);
    }, 400);
    return () => clearTimeout(id);
  }, [selected]);

  return (
    <div className="entry-page">
      <div className="vials-wrapper">
        <img src={loading} alt="Loading" />
      </div>
      <div>
        <Pager hideLeftArrow hideRightArrow current={selected} steps={5} />
      </div>
    </div>
  );
};
