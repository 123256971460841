import {
  LOGIN_FAILED,
  LOGIN_SUBMITTED,
  LOGIN_SUCCEEDED,
  PASSWORD_RESET_STARTED,
  RESET_LOGIN_STATE
} from "../actions/authentication";
import { SESSION_ESTABLISHED } from "../actions/session";

const initialState = {
  email: "",
  password: "",
  // email: 'cyril+220805a@liquid-state.com',
  inProgress: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_LOGIN_STATE:
      return initialState;
    case PASSWORD_RESET_STARTED:
      return { ...state, email: action.payload.email };
    case LOGIN_SUBMITTED:
      return { ...state, inProgress: true, error: null };
    case LOGIN_SUCCEEDED:
      return {
        ...state,
        email: action.payload.identity,
        inProgress: true,
        error: null
      };
    case SESSION_ESTABLISHED:
      return { ...state, inProgress: false, error: null };
    case LOGIN_FAILED:
      return {
        ...state,
        inProgress: false,
        error: "The login details you entered are incorrect."
      };
    default:
      return state;
  }
};
