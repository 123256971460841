import React from "react";
import { useHistory } from "react-router-dom";
// import { IWARouterContext } from './Router';

/** A link component similar to the one from react-router except specifically designed
 * for working with IWAs and supporting the style of navigation iwa-router provides.
 */

const Link = ({ to, children }) => {
  const history = useHistory();

  const onClick = e => {
    e.preventDefault();
    e.stopPropagation();
    const parts = document.location.pathname.split("/");

    if (to[0] === "/") {
      history.push(to);
    } else {
      parts[parts.length - 1] = to;
      history.push(`/${parts.join("/")}`);
    }

    // if (to.indexOf(`/${parts[3]}`) === 0) {
    //   history.push(to);
    // } else if (parts.length > 3) {
    //   history.push(`/${parts[3]}${to}`);
    // } else {
    //   history.push(to);
    // }
  };

  //eslint-disable-next-line
  return (
    <a onClick={e => onClick(e)} href="https://example.com">
      {children}
    </a>
  );
};

export default Link;

// export default class Link extends PureComponent {
//   static propTypes = {
//     to: PropTypes.string.isRequired,
//     children: PropTypes.node.isRequired,
//     app: PropTypes.string,
//     tab: PropTypes.string,
//     replace: PropTypes.bool,
//     data: PropTypes.object,
//   };

//   static defaultProps = {
//     app: undefined,
//     tab: undefined,
//     replace: false,
//     data: undefined,
//   };

//   onClick = (e, router) => {
//     e.preventDefault();
//     e.stopPropagation();
//     const { to } = this.props;
//     history.push(to);
//     // const { to, app, tab, replace, data } = this.props;
//     // const target = router.resolve(to, app);
//     // router.navigate(target, {
//     //   additionalData: data,
//     //   app,
//     //   replace,
//     //   tab,
//     // });
//   };

//   render() {
//     const { children } = this.props;
//     return (
//       // <IWARouterContext.Consumer>
//       //   {router => {
//       //     const { children } = this.props;
//       //     return (
//       //       // eslint-disable-next-line jsx-a11y/anchor-is-valid
//       //       <a onClick={e => this.onClick(e, router)} href="#">
//       //         {children}
//       //       </a>
//       //     );
//       //   }}
//       // </IWARouterContext.Consumer>
//       // eslint-disable-next-line jsx-a11y/anchor-is-valid
//       <a onClick={e => this.onClick(e)} href="#">
//         {children}
//       </a>
//     );
//   }
// }
