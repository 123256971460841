// returns a promise, handy for waiting for things to happen before a modal closes
// only supports first element of array return values from selectors.
function waitForStoreUpdate(store, selector, initialCurrentValue = true) {
  let currentValue = initialCurrentValue;
  return new Promise(resolve => {
    store.subscribe(() => {
      const previousValue = currentValue;
      const loadingState = selector(store.getState());

      const loading = Array.isArray(loadingState) ? loadingState[0] : loadingState;

      currentValue = loading;

      if (previousValue !== currentValue) {
        resolve();
      }
    });
  });
}

export default waitForStoreUpdate;
