import { ISetPermissionsMap } from './actions';

const initialState = {
  permissionsMap: {},
};

export default (state = initialState, action: ISetPermissionsMap) => {
  switch (action.type) {
    case 'authorisation/setPermissionsMap':
      return {
        ...state,
        permissionsMap: action.payload.map,
      };
    default:
      return state;
  }
};
