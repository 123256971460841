import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LngDetector from 'i18next-browser-languagedetector';

import resources from './locales/index';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LngDetector)
  .init({
    resources,
    detection: {
      order: ['querystring', 'navigator'],
    },
    fallbackLng: 'en',
    debug: process.env.NODE_ENV !== 'production',
    ns: ['common'],
    defaultNS: 'common',

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;