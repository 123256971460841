import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, HeaderImage } from '@common';
import { Card } from 'antd';
import { personalisationSubmitted } from '../../redux/actions/registration';
import { personalise } from '../../assets';
import Form from './Form';

import './styles.less';

export const PersonaliseForm = ({ submitting, onSubmit }) => {
  return (
    <Container fixed noPadding className="onboarding personalise">
      <HeaderImage imgUrl={personalise} />
      <Card>
        <h1>Tell us about yourself</h1>
        <p>
          Help us personalise the content in this app by answering the following
          questions.
        </p>
        <Form submitting={submitting} onSubmit={onSubmit} />
      </Card>
    </Container>
  );
};

PersonaliseForm.propTypes = {
  submitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  submitting: state.registration.submitting,
});

export default connect(
  mapStateToProps,
  { onSubmit: personalisationSubmitted },
)(PersonaliseForm);
