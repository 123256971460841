import { initialisation } from '@common';
import registration from './registration';
import passwordReset from './password-reset';
import login from './login';
import isOnline from './isOnline';

export default {
  initialisation: initialisation.reducer,
  registration,
  passwordReset,
  login,
  isOnline,
};
