import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Input } from "antd";
import { searchDocuments, updateSearchText } from "../redux/actions";

class SearchForm extends React.Component {
  state = {
    currentSearchText: "",
    latestSearchText: "",
    hasSearchedOnce: false
  };

  constructor(props) {
    super(props);
    this.searchRef = React.createRef();
  }

  componentDidMount() {
    const { initialSearchText } = this.props;
    this.setState({ currentSearchText: initialSearchText });

    // if (document.location.search) {
    //   const searchValue = new URLSearchParams(document.location.search).get('search')?.trim();
    //   if (searchValue) {
    //     this.setState({ initialSearchText: searchValue })
    //     const { onSearch, documentIssueToken } = this.props;
    //     // onSearch(searchValue, documentIssueToken);
    //     // const { onChange, onSearch, documentIssueToken } = this.props;
    //     // onChange(searchValue);
    //     // onSearch(searchValue, documentIssueToken);
    //   }
    // }

    // const { history } = this.props;
    // this.backListener = history.listen(location => {
    //   if (location.search && location.search.indexOf('?search=') > -1) {
    //     const searchValue = location.search.replace('?search=', '');
    //     this.setState({ initialSearchText: searchValue });
    //     console.log('Mounting search form and performing search');
    //     this.onPerformSearch(searchValue);
    //   }
    // });
  }

  componentWillUnmount() {
    if (this.backListener) {
      this.backListener();
    }
  }

  componentDidUpdate(prevProps) {
    const { hasSearchedOnce } = this.state;
    const { isReady, initialSearchText } = this.props;
    if (initialSearchText !== prevProps.initialSearchText) {
      this.setState({ currentSearchText: initialSearchText });
    }

    // const { isReady, searchText } = this.props;
    // console.log('isReady', isReady);
    // const { initialSearchText, latestSearchText, hasSearchedOnce } = this.state;

    // if (initialSearchText === '' && searchText) {
    //   this.setState({ initialSearchText: searchText });
    // }

    // if (document.location.search) {
    //   const searchValue = new URLSearchParams(document.location.search).get('search')?.trim();
    //   if (isReady && searchValue && !hasSearchedOnce && searchValue !== latestSearchText) {
    //     this.setState({ initialSearchText: searchValue });
    //     console.log('Performing search due to URL search param')
    //     this.onPerformSearch(searchValue);
    //   }
    // }

    if (isReady && initialSearchText && !hasSearchedOnce) {
      this.onPerformSearch(initialSearchText);
    }

    // console.log('search text: ', searchText);
    // console.log('previous search text: ', prevProps.searchText);
    // console.log('last search type: ', searchType);
    // if (searchText !== '' && ((prevProps.searchText !== searchText) || (searchType === 'global'))) {
    //   const { onChange, onSearch, documentIssueToken } = this.props;
    //   // onChange(searchValue);
    //   // this.setState({ initialSearchText: searchText });
    //   onSearch(searchText, documentIssueToken);
    //   // if (document.location.search) {
    //   //   const searchValue = new URLSearchParams(document.location.search).get('search');
    //   //   if (searchValue) {
    //   //     const { onChange, onSearch, documentIssueToken } = this.props;
    //   //     onChange(searchValue);
    //   //     onSearch(searchValue, documentIssueToken);
    //   //   }
    //   // }
    // }
  }

  onSearchInputSubmitted = value => {
    let url = document.location.pathname;
    if (value) {
      url += "?search=" + value;
    }
    const { history } = this.props;
    history.push(url);
    this.onPerformSearch(value);
  };

  onPerformSearch = value => {
    const { onSearch, onChange, documentIssueToken } = this.props;
    if (!value || !value.length) {
      this.onClearSearch();
      return;
    }
    window.scrollTo(0, 0);
    this.setState({ hasSearchedOnce: true, latestSearchText: value });
    onChange(value);
    onSearch(value, documentIssueToken);

    setTimeout(() => {
      if (this.searchRef) this.searchRef.blur();
    }, 250);
  };

  onChange = e => {
    // const { onChange } = this.props;
    // onChange(e.target.value);
    this.setState({ currentSearchText: e.target.value });
  };

  render = () => {
    const { isSearchActive, searchText } = this.props;
    const { currentSearchText } = this.state;
    let enterButtonClass = searchText !== "" ? "searching" : "";
    if (isSearchActive) enterButtonClass = "searched";

    return (
      <div className={`search-form-container ${enterButtonClass}`}>
        <Input.Search
          className={enterButtonClass}
          type="search"
          placeholder="Search for a topic or keyword"
          // onSearch={this.onPerformSearch}
          onSearch={this.onSearchInputSubmitted}
          onChange={this.onChange}
          value={currentSearchText}
          enterButton
          ref={ref => {
            this.searchRef = ref;
          }}
        />
      </div>
    );
  };
}

const mapStateToProps = state => ({
  isSearchActive: state.search.isSearchActive,
  searchText: state.search.searchText,
  searchType: state.search.searchType
});

SearchForm.propTypes = {
  isReady: PropTypes.bool,
  documentIssueToken: PropTypes.string,
  isSearchActive: PropTypes.bool,
  searchText: PropTypes.string,
  searchType: PropTypes.string,
  onSearch: PropTypes.func,
  onChange: PropTypes.func
};

SearchForm.defaultProps = {
  isReady: false,
  documentIssueToken: null,
  isSearchActive: false,
  searchText: "",
  searchType: "",
  onSearch: () => {},
  onChange: () => {}
};

const ConnectedSearchForm = connect(mapStateToProps, {
  onChange: updateSearchText,
  onSearch: searchDocuments
})(SearchForm);

export default ConnectedSearchForm;

// export default (props) => (
//   <WithRouter>
//     <ConnectedSearchForm {...props} />
//   </WithRouter>
// );
